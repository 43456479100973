import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowModes,
  GridRowModesModel,
  GridSlots,
  useGridApiRef,
} from "@mui/x-data-grid"
import {
  useCreateProxyDomainConfig,
  useDeleteProxyDomainConfig,
  useFetchProxyDomainConfigs,
  useUpdateProxyDomainConfig,
} from "@src/api/admin/content-acquisition/proxyDomainConfig"
import { FC, useState, useEffect } from "react"
import { theme } from "@src/theme"
import { Stack } from "@mui/material"
import { AddConfigToTable } from "./AddConfigToTable"
import {
  CrudActions,
  handleCancelClick,
  handleDeleteClick,
  handleEditClick,
  handleRowEditStop,
  handleRowModesModelChange,
  handleSaveClick,
  processRowUpdate,
} from "../../DataGrid/editing"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import { ProxyDomainConfig } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import { configColumns } from "./columns"
import { useFetchDomains } from "@src/api/admin/content-acquisition/domains"
import { useFetchProxies } from "@src/api/admin/content-acquisition/proxies"

export const ProxyDomainConfigTable: FC = () => {
  const apiRef = useGridApiRef()
  const { data: configs, isLoading } = useFetchProxyDomainConfigs()
  const [rows, setRows] = useState<ProxyDomainConfig[]>(configs || [])
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

  const { data: domains } = useFetchDomains()
  const { data: proxies } = useFetchProxies()
  const { mutateAsync: deleteConfigs } = useDeleteProxyDomainConfig()
  const { mutateAsync: createConfigs } = useCreateProxyDomainConfig()
  const { mutateAsync: updateConfigs } = useUpdateProxyDomainConfig()

  const crudActions: CrudActions = {
    delete: async (id: number) => deleteConfigs(id),
    update: async (row: ProxyDomainConfig) => updateConfigs(row),
    create: async (row: ProxyDomainConfig) => createConfigs(row),
  }

  useEffect(() => setRows(configs || []), [configs])

  const actionColumns: GridColDef[] = [
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(row, rowModesModel, setRowModesModel)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id, rowModesModel, setRowModesModel, rows, setRows)}
              color="inherit"
            />,
          ]
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id, rowModesModel, setRowModesModel)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id, crudActions, apiRef)}
            color="inherit"
          />,
        ]
      },
    },
  ]

  return (
    <Stack spacing={2}>
      <DataGrid
        sx={{
          '.MuiDataGrid-booleanCell[data-value="true"]': {
            color: theme.palette.success.main,
          },
          '.MuiDataGrid-booleanCell[data-value="false"]': {
            color: "red",
          },
        }}
        density="compact"
        rows={rows}
        columns={[...configColumns(domains, proxies), ...actionColumns].map((column) => ({
          ...column,
          headerClassName: "super-app-theme--header",
        }))}
        autoHeight
        loading={isLoading}
        disableRowSelectionOnClick
        editMode="row"
        onRowModesModelChange={handleRowModesModelChange(setRowModesModel)}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={(newRow: ProxyDomainConfig, oldRow: ProxyDomainConfig) =>
          processRowUpdate(newRow, oldRow, rows, setRows, crudActions)
        }
        onProcessRowUpdateError={(_error) => console.log("Error updating row")} // replace with a toast
        rowModesModel={rowModesModel}
        apiRef={apiRef}
        slots={{
          toolbar: AddConfigToTable as GridSlots["toolbar"],
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, rows },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "domain_id", sort: "desc" }],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
      />
    </Stack>
  )
}
