import { FetchedMeeting } from "@api/meetings"
import { MeetingSearchResult } from "@api/search"
import { TranscriptList } from "@components/MeetingDetail/TranscriptsTab/TranscriptList"
import { NoResultsContainer } from "@components/shared/NoResultsContainer"
import { TranscriptWithPerson } from "@interfaces/transcript"
import { Box, Card, CardContent } from "@mui/material"
import { MeetingPageContext } from "@pages/MeetingPage"
import { FC, useContext } from "react"

export interface TranscriptListContainerProps {
  searchResults: MeetingSearchResult
  transcripts: [string, TranscriptWithPerson][]
  meetingData: FetchedMeeting
}

export const TranscriptsListContainer: FC<TranscriptListContainerProps> = ({
  searchResults,
  transcripts,
  meetingData,
}) => {
  const { searchTermsInputRef } = useContext(MeetingPageContext)
  if (searchResults.total_hits == 0) {
    return (
      <div onClick={() => searchTermsInputRef.current.focus()}>
        <NoResultsContainer
          minHeight="20vh"
          subHeading="Change your search terms and try again!"
          subHeaderSx={{ textDecoration: "underline", cursor: "pointer" }}
        />
      </div>
    )
  } else {
    return <TranscriptList transcripts={transcripts} meetingData={meetingData} />
  }
}
