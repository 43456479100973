import { FC, useState, useEffect, useContext } from "react"
import { Switch, FormControlLabel, Stack, Button, Dialog, DialogTitle, DialogContent } from "@mui/material"
import { UserOrganizationAboutForm } from "@components/shared/UserAboutForm"
import { getAbout, useSetAbout } from "@api/admin/users"
import { AdminUserContext } from "./context"
import { useToast } from "@components/shared/ToastHook"

export const UpdateAboutModal: FC<{
  handleClose: () => void
  open: boolean
}> = ({ handleClose, open }) => {
  const contextData = useContext(AdminUserContext)
  const { ids } = contextData
  const [enabled, setEnabled] = useState(false)
  const { data, isLoading } = getAbout(ids[0])

  const setToast = useToast()

  const onUpdateSuccess = () => {
    setToast("User about updated successfully.", 5000)
  }

  const { mutate: setAbout } = useSetAbout(ids[0], onUpdateSuccess)

  useEffect(() => {
    if (!isLoading) {
      setEnabled(Boolean(data))
    }
  }, [data, isLoading])

  if (isLoading) {
    return null
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg">
      <DialogTitle>About</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={<Switch checked={enabled} onChange={(_event, checked) => setEnabled(checked)} />}
          label="Enable Personalized Summaries"
        />
        {enabled && <UserOrganizationAboutForm aboutData={data || {}} setAbout={setAbout} />}
        {/*
        We only need to add the save button if we want to remove the permission
        because in all other cases, the shared component will handle it
      */}
        {data && enabled === false && (
          <Stack direction="row" spacing={1}>
            <Button variant="contained" onClick={() => setAbout({ formData: null })}>
              Save
            </Button>
            <Button onClick={() => setEnabled(Boolean(data))}>Cancel</Button>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  )
}
