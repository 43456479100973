import { MeetingSearchResult } from "@api/search"
import { Chip, Stack } from "@mui/material"
import { FC } from "react"
import { PdfButton } from "./PdfButton"

export interface TotalMatchesChipProps {
  searchTerms: string[]
  meetingSearchResult: MeetingSearchResult
  isMeetingSearchResultLoading: boolean
}

export const TotalMatchesChip: FC<TotalMatchesChipProps> = ({
  searchTerms,
  meetingSearchResult,
  isMeetingSearchResultLoading,
}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap" rowGap={2}>
      {isMeetingSearchResultLoading && <Chip color="primary" label="loading..." />}
      {searchTerms.length > 0 && !isMeetingSearchResultLoading && (
        <Chip color="primary" label={`Total matches: ${meetingSearchResult.total_hits}`} />
      )}
      <PdfButton />
    </Stack>
  )
}
