export const removeSearchTermString = (prevParams: string | URLSearchParams, removeTerm: string) => {
  const params = new URLSearchParams(prevParams)
  const terms = params.getAll("terms")

  params.delete("terms")
  terms.filter((term) => term !== removeTerm).forEach((term) => params.append("terms", term))
  return params.toString()
}

export const addSearchTermString = (prevParams: string | URLSearchParams, newTerm: string) => {
  const params = new URLSearchParams(prevParams)
  const terms = params.getAll("terms")
  terms.push(newTerm)
  const uniqueTerms = new Set<string>(terms)

  params.delete("terms")
  uniqueTerms.forEach((term) => params.append("terms", term))
  return params.toString()
}

export interface MeetingDetailsParamsStringProps {
  terms?: string[] | null
  transcriptId?: string | number | null
  activeTerm?: string | null
  startTime?: number | null
  prevParams?: string | URLSearchParams
  proximity?: number | null
  mustHaveTerms?: string[] | null
  exclude?: boolean | null
  refresh?: boolean | null
}

export const generateParamsString = (paramsStringProps?: MeetingDetailsParamsStringProps) => {
  if (!paramsStringProps) {
    return ""
  }
  const { terms, transcriptId, activeTerm, startTime, prevParams, proximity, mustHaveTerms, exclude, refresh } =
    paramsStringProps
  const params = new URLSearchParams(prevParams)
  if (terms === null) {
    params.delete("terms")
  } else if (terms) {
    params.delete("terms")
    terms.forEach((term) => params.append("terms", term))
  }
  if (transcriptId === null) {
    params.delete("transcript_id")
  } else if (transcriptId) {
    params.set("transcript_id", transcriptId.toString())
  }
  if (activeTerm === null) {
    params.delete("active-term")
  } else if (activeTerm) {
    params.set("active-term", activeTerm)
  }
  if (startTime === null) {
    params.delete("startTimeSeconds")
  } else if (startTime) {
    params.set("startTimeSeconds", startTime.toString())
  }
  if (exclude === null) {
    params.delete("exclude")
  } else if (exclude !== undefined) {
    params.set("exclude", exclude.toString())
  }
  if (proximity === null) {
    params.delete("proximity")
  } else if (proximity) {
    params.set("proximity", proximity.toString())
  }
  if (mustHaveTerms === null) {
    params.delete("must-have-terms")
  } else if (mustHaveTerms) {
    params.delete("must-have-terms")
    mustHaveTerms.forEach((term) => params.append("must-have-terms", term))
  }
  if (refresh === null) {
    params.delete("refresh")
  } else if (refresh) {
    params.set("refresh", refresh.toString())
  }
  return params.toString()
}

export const generateURL = (meetingId: string | number, paramsStringProps?: MeetingDetailsParamsStringProps) => {
  const paramsString = generateParamsString(paramsStringProps)
  return `/meetings/${meetingId}${paramsString ? "?" : ""}${paramsString}`
}

export const generateMinnieURL = (prefix: string) => {
  if (!process.env.REACT_APP_GOOGLE_ENV) {
    const searchHostUrl = process.env.REACT_APP_SEARCH_API_URL || "http://localhost:8001/"
    prefix = `${searchHostUrl}${prefix}`
  }
  return prefix
}
