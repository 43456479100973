import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { useFetchContentAcquisitionJobs } from "@src/api/admin/content-acquisition/jobs"
import { useFetchProxies } from "@src/api/admin/content-acquisition/proxies"
import { intTypeToStringMapping, NameSpaceType } from "@src/interfaces/content-acquisition/proxyEnvironmentTypes"
import { Domain, Job, ProxyType } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import { FC, useMemo } from "react"
import { theme } from "@src/theme"
import { Proxy } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import { Button, TextField } from "@mui/material"
import { useCreateProxyDomainConfig } from "@src/api/admin/content-acquisition/proxyDomainConfig"
import { getUtcDateforNow } from "@src/interfaces/content-acquisition/timeUtilities"
import { subDays } from "date-fns"
import { CommonUrlParams } from "@interfaces/content-acquisition/requestParams"
import { useToast } from "@components/shared/ToastHook"

export const ProxySuccessByDomain: FC<{
  domain: Domain
  daysBack: number
  setDaysBack: React.Dispatch<React.SetStateAction<number>>
}> = ({ domain, daysBack, setDaysBack }) => {
  const jobsParams: CommonUrlParams = useMemo(() => {
    return {
      dateFilters: [
        {
          attribute: "started_at",
          dateRange: [subDays(getUtcDateforNow(), daysBack + 1), getUtcDateforNow()],
          filterBy: "in_range",
        },
      ],
      attributeFilters: { domain_id: domain.id, exclude_by_error_type: "garbage_urls" },
    }
  }, [daysBack, domain])

  const { data: jobsData, isLoading: jobsAreLoading } = useFetchContentAcquisitionJobs(jobsParams)
  const { data: proxies, isLoading: proxiesAreLoading } = useFetchProxies()
  const toast = useToast()
  const { mutate: updateConfig } = useCreateProxyDomainConfig(() => toast("Success"))

  const disableProxyForDomain = (proxy_id: number) => {
    updateConfig({ domain_id: domain.id, proxy_id, disabled: true })
  }

  const proxySuccessTotal = (jobs: Job[]) => {
    const totals = jobs.reduce(
      (acc, job) => {
        const proxyId = job?.proxy_id
        if (!proxyId) return acc
        if (!acc[proxyId]) acc[proxyId] = { total: 0, success: 0 }

        acc[proxyId].total++
        if (job.success) acc[proxyId].success++

        return acc
      },
      {} as { [key: string]: { total: number; success: number } },
    )

    return totals
  }

  const proxyIsDisabledForDomain = (proxyId?: number) => {
    if (!proxyId) return false
    return domain.proxies_scraped_domains_configs?.find((config) => config.proxy_id === proxyId)?.disabled
  }

  const proxyRows = useMemo(() => {
    if (!proxies || !jobsData) return []
    if (!jobsData) return []

    const successTotals = proxySuccessTotal(jobsData.jobs)
    const tableRows: Proxy[] = []
    proxies.forEach((proxy) => {
      if (!successTotals[proxy.id]) return
      const success = successTotals[proxy.id].success
      const total = successTotals[proxy.id].total
      const percentage = total === 0 ? 0 : success / total
      tableRows.push({ ...proxy, ...{ successRate: percentage, totalCount: total, successCount: success } })
    })
    return tableRows
  }, [proxies, jobsData?.jobs])

  const proxyColumns: GridColDef[] = [
    {
      field: "id",
      flex: 0.1,
      headerName: "ID",
      type: "number",
    },
    { field: "name", flex: 1, headerName: "Name" },
    {
      field: "internal_ip",
      flex: 1,
      description: "Used by the Proxy Provider Downloader.",
      headerName: "Internal Ip",
    },
    {
      field: "external_ip",
      flex: 1,
      description: "Unique, visible identifier shown to target domains.",
      headerName: "External Ip",
    },
    { field: "zone", flex: 0.5, headerName: "Zone" },
    {
      field: "provider",
      flex: 1,
      description: "Host provider for proxy",
      headerName: "Provider",
    },
    {
      field: "environment_type",
      flex: 1,
      headerName: "Environment Type",
      type: "string",
      renderCell: (params) => intTypeToStringMapping[params.row.environment_type as NameSpaceType] || "Unknown",
    },
    {
      field: "agent_type",
      flex: 1,
      headerName: "Type",
      type: "singleSelect",
      valueOptions: Object.values(ProxyType),
    },
    { field: "successCount", flex: 1, headerName: "Success Count" },
    { field: "totalCount", flex: 1, headerName: "Total Count" },
    {
      field: "successRate",
      flex: 1,
      headerName: "Success Rate",
      valueGetter: (value) => {
        if (value === 0) return 0
        if (!value) return 100
        return value * 100
      },
      valueFormatter: (value) => {
        return `${(value as number).toFixed(2)}%`
      },
    },
    {
      field: "disableForDomain",
      headerName: "",
      renderCell: (params) => {
        if (proxyIsDisabledForDomain(params.row.id)) {
          return null
        }
        return <Button onClick={() => disableProxyForDomain(params.row.id)}>Disable</Button>
      },
    },
  ]

  return (
    <>
      <TextField
        id="outlined-number"
        label="Days Back for Success Rate"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        sx={{ marginBottom: theme.spacing(2) }}
        value={daysBack}
        onChange={(e) => setDaysBack(Number(e.target.value))}
      />
      <DataGrid
        autoHeight
        {...proxyRows}
        initialState={{
          columns: {
            columnVisibilityModel: {
              provider: false,
            },
          },
          pagination: { paginationModel: { pageSize: 25 } },
          sorting: {
            sortModel: [{ field: "successRate", sort: "asc" }],
          },
        }}
        sx={{
          '.MuiDataGrid-booleanCell[data-value="true"]': {
            color: theme.palette.success.main,
          },
          '.MuiDataGrid-booleanCell[data-value="false"]': {
            color: theme.palette.error.main,
          },
        }}
        density="compact"
        loading={proxiesAreLoading || jobsAreLoading}
        rows={proxyRows || []}
        disableRowSelectionOnClick
        getRowClassName={(params) => (proxyIsDisabledForDomain(params?.row?.id) ? "super-app-theme--red-row" : "ddddd")}
        columns={proxyColumns.map((column) => ({ ...column, headerClassName: "super-app-theme--header" }))}
      />
    </>
  )
}
