import { FC, useState, useEffect, useMemo } from "react"
import { Button, CircularProgress, Stack, Typography } from "@mui/material"
import { getMeetingsBriefingCost } from "@api/meetings"
import { useParams } from "react-router-dom"
import { IMeetingRouteParams } from "@api/interfaces"
import { getPrompts } from "@api/prompts"
import { useOpenModalKey } from "../../shared/OpenModalHook"
import { CreditSpentConfirmation, roundCredits } from "../../shared/CreditSpentConfirmation"
import { useToast } from "../../shared/ToastHook"
import { PersonalBriefingPromptSelector } from "../../shared/PersonalBriefingPromptSelector"
import PostAddIcon from "@mui/icons-material/PostAdd"
import { UseMutateFunction } from "react-query"
import { AxiosResponse } from "axios"
import { PublicBriefing } from "@interfaces/briefing"
import {
  BriefingAlreadyCreatedModal,
  briefingAlreadyCreatedModalKey,
} from "@components/shared/BriefingAlreadyCreatedModal"

const personalBriefingModalKey = "PERSONAL_BRIEFING_COST_MODAL"

export const PersonalBriefingCreation: FC<{
  onConfirmCreatePrompt: UseMutateFunction<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosResponse<any, any>, // these anys are copied from the actual type
    unknown,
    {
      promptIds: number[]
    },
    unknown
  >
  currentUsersBriefings?: PublicBriefing[]
  onSuccess?: () => void
  isLoading?: boolean
}> = ({ onConfirmCreatePrompt, currentUsersBriefings, onSuccess, isLoading }) => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data: prompts, isFetched } = getPrompts("personal")
  const [selectedPrompts, setSelectedPrompts] = useState<Set<number>>(new Set())
  const toast = useToast()
  const openCostModal = useOpenModalKey(personalBriefingModalKey)
  const openBriefingAlreadyCreatedModal = useOpenModalKey(briefingAlreadyCreatedModalKey)
  const { data: briefingCost } = getMeetingsBriefingCost(parseInt(meetingId))

  const availablePrompts =
    prompts?.filter(({ id }) => {
      if (selectedPrompts.has(id)) {
        return false
      } else {
        return true
      }
    }) || []

  useEffect(() => {
    if (prompts && isFetched && selectedPrompts.size === 0) {
      if (availablePrompts.length === 0) {
        setSelectedPrompts(new Set())
      } else {
        setSelectedPrompts(new Set([availablePrompts[0].id]))
      }
    }
  }, [isFetched, availablePrompts.length])

  const selectedPromptsCollection = useMemo(() => {
    return prompts?.filter((prompt) => Array.from(selectedPrompts).includes(prompt.id))
  }, [selectedPrompts])

  const promptsAlreadyCreatedByUser = useMemo(() => {
    const promptsAlreadyCreated = [] as { promptCreated: boolean; promptName: string }[]

    if (currentUsersBriefings && selectedPromptsCollection) {
      selectedPromptsCollection.forEach((prompt) => {
        const currentBriefing = currentUsersBriefings.find(
          (briefing) => briefing.name.trim().toLowerCase() === prompt.name.trim().toLowerCase(),
        )

        if (currentBriefing) {
          promptsAlreadyCreated.push({ promptCreated: true, promptName: prompt.name })
        }
      })
    }
    return promptsAlreadyCreated
  }, [currentUsersBriefings, selectedPromptsCollection])

  const promptsAlreadyCreatedNames = promptsAlreadyCreatedByUser.map((prompt) => prompt.promptName)

  return (
    <>
      <Stack spacing={1} alignItems="flex-start">
        <Typography>Add your personalized briefing prompts.</Typography>
        <PersonalBriefingPromptSelector selectedPrompts={selectedPrompts} setSelectedPrompts={setSelectedPrompts} />
        <Stack spacing={2} direction="row" alignItems="center">
          <Button
            variant="contained"
            startIcon={<PostAddIcon />}
            onClick={() => {
              if (promptsAlreadyCreatedByUser.length > 0) {
                openBriefingAlreadyCreatedModal()
              } else {
                openCostModal()
              }
            }}
            disabled={selectedPrompts.size === 0 || isLoading}
          >
            Create Personal Briefing
          </Button>
          {briefingCost?.cost ? (
            <Typography>{`Credit Cost: ${roundCredits(briefingCost.cost * selectedPrompts.size)}`}</Typography>
          ) : null}
          {isLoading && (
            <>
              <Typography>Briefing Processing</Typography>
              <CircularProgress />
            </>
          )}
        </Stack>
      </Stack>
      {briefingCost?.cost ? (
        <CreditSpentConfirmation
          modalKey={personalBriefingModalKey}
          onConfirm={() => {
            onConfirmCreatePrompt({ promptIds: Array.from(selectedPrompts) })
            toast("A Personal Briefing is being created. We will email you when it's finished")
            if (onSuccess) {
              onSuccess()
            }
          }}
          cost={briefingCost.cost * selectedPrompts.size}
        />
      ) : null}
      {promptsAlreadyCreatedByUser && (
        <BriefingAlreadyCreatedModal briefingNames={promptsAlreadyCreatedNames} onConfirm={openCostModal} />
      )}
    </>
  )
}
