import { PublicBriefing } from "@interfaces/briefing"
import { BriefingCreditUsage } from "@interfaces/briefingCreditUsage"
import { BriefingCardWithRetry } from "./BriefingCardWithRetry"

export const constructBriefingCards = (
  briefings: PublicBriefing[],
  briefingCreditUsagesMap: Map<number, BriefingCreditUsage[]>,
) => {
  return briefings.map((briefing) => (
    <BriefingCardWithRetry
      key={briefing.id}
      briefing={briefing}
      ownerIds={briefingCreditUsagesMap.get(briefing.id)?.map((briefingCreditUsage) => briefingCreditUsage.user_id)}
    />
  ))
}
