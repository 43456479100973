import { Dispatch, SetStateAction, createContext, FC, useState, useContext, Context } from "react"

interface OpenModalContextProps<T> {
  openModalKeys: Set<string>
  setOpenModalKeys: Dispatch<SetStateAction<Set<string>>>
  data?: T
  setData?: (data: T) => void
}

const OpenModalContext = createContext<OpenModalContextProps<unknown>>({
  openModalKeys: new Set(),
  setOpenModalKeys: () => {},
  data: undefined,
  setData: () => {},
})

export const useModalKey = (key: string) => {
  const { openModalKeys } = useContext(OpenModalContext)
  return openModalKeys.has(key)
}

export const useOpenModalKey = (key: string) => {
  const { setOpenModalKeys } = useContext(OpenModalContext)
  return () => {
    setOpenModalKeys((prevSet) => {
      const newSet = new Set(prevSet)
      newSet.add(key)
      return newSet
    })
  }
}

export const useCloseModalKey = (key: string) => {
  const { setOpenModalKeys, data, setData } = useContext(OpenModalContext)
  return () => {
    if (data && setData) {
      setData(undefined)
    }
    setOpenModalKeys((prevSet) => {
      const newSet = new Set(prevSet)
      newSet.delete(key)
      return newSet
    })
  }
}

export const useData = <T,>() => {
  const { data } = useContext(OpenModalContext as Context<OpenModalContextProps<T>>)

  return data
}

export const useSetData = <T,>() => {
  const { setData } = useContext(OpenModalContext as Context<OpenModalContextProps<T>>)
  return (newData: T) => {
    if (setData) {
      setData(newData)
    }
  }
}

export const OpenModalProvider: FC = ({ children }) => {
  const [openModalKeys, setOpenModalKeys] = useState<Set<string>>(new Set())
  const [data, setData] = useState<unknown>()

  return (
    <OpenModalContext.Provider value={{ openModalKeys, setOpenModalKeys, data, setData }}>
      {children}
    </OpenModalContext.Provider>
  )
}
