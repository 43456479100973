import { CommonUrlParams, DateFilters } from "@interfaces/content-acquisition/requestParams"
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid"

export interface QueryOptions {
  sortModel?: GridSortModel
  filterModel?: GridFilterModel
}

export const get_filter_by_field_type = (field: string, value: string | Date) => {
  if (value instanceof Date) {
    const param: DateFilters = {
      attribute: field,
      dateRange: [value, undefined],
      filterBy: "on_day",
    }
    return { dateFilters: [param] }
  } else {
    const param: { [key: string]: string } = {}
    param[field] = value
    return { attributeFilters: param }
  }
}

export const build_search = (queryOptions: QueryOptions, paginationModel: { page: number; pageSize: number }) => {
  let filters: CommonUrlParams = {
    tableParams: {
      page_size: paginationModel.pageSize,
      page: paginationModel.page,
    },
  }

  if (queryOptions.sortModel?.[0] && filters.tableParams) {
    filters.tableParams.order = [
      { attribute: queryOptions.sortModel[0].field, direction: queryOptions.sortModel[0].sort as "asc" | "desc" },
    ]
  }

  if (queryOptions.filterModel?.items[0] && queryOptions.filterModel.items[0].value) {
    const fieldFilters = get_filter_by_field_type(
      queryOptions.filterModel.items[0].field,
      queryOptions.filterModel.items[0].value,
    )
    filters = { ...filters, ...fieldFilters }
  }

  return filters
}
