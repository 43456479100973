import moment from "moment"
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { defaultDateRange } from "../components/shared/config"
import { updateQuickstartSearched } from "./users"
import { RubyObject } from "../interfaces/default"
import { BaseMeeting, Meeting } from "../interfaces/meeting"
import { TranscriptHits, TranscriptWithPerson } from "@interfaces/transcript"
import { PublicBriefing } from "../interfaces/briefing"
import { NaicsCode } from "@src/interfaces/naicsCode"
import { Opportunity } from "@src/interfaces/opportunity"
import { BriefingCreditUsage } from "@interfaces/briefingCreditUsage"
import { generateMinnieURL } from "@functions/generateURL"

const prefix = "/meetings"

export const useAdvancedSearch = (
  searchTerms: any,
  filterParams: any,
  campaignId: any,
  searchDateRange: any,
  mustIncludeTerms: any,
  pageNumber: number,
  sortBy: string,
) => {
  const queryClient = useQueryClient()
  const dateRange: any = {
    published_at: searchDateRange ? moment(searchDateRange[0]).unix() : moment(defaultDateRange[0]).unix(),
    published_end: searchDateRange ? moment(searchDateRange[1]).unix() : moment(defaultDateRange[1]).unix(),
  }
  const axiosInstance = useAuthAxios()
  searchTerms = searchTerms?.filter((term: string) => term !== "")
  mustIncludeTerms = mustIncludeTerms?.filter((term: string) => term !== "")

  const requestBody: { [key: string]: any } = {
    searchTerms: searchTerms.concat(mustIncludeTerms),
    filterParams: filterParams,
    campaign_id: campaignId,
    page_of_origin: "dashboard",
    date_range: dateRange,
    page_number: pageNumber,
    sort_by: sortBy === "Most Recent" ? "published_at" : "meeting_hits",
  }

  if (mustIncludeTerms !== null && mustIncludeTerms.length > 0)
    requestBody.advancedSearch = { must_include: mustIncludeTerms, should_include: searchTerms }

  return useQuery<{ meetings: Meeting[]; total_hits: number; total_meetings: number }>(
    ["meetings", searchTerms, filterParams, dateRange, mustIncludeTerms, pageNumber, sortBy],
    async () => {
      const response = await (await axiosInstance).post("meetings/advanced_search", requestBody)
      return response.data
    },
    {
      enabled:
        searchTerms?.filter((term: string) => term !== "")?.length > 0 ||
        mustIncludeTerms?.filter((term: string) => term !== "")?.length > 0,
      onSuccess: () => updateQuickstartSearched(queryClient),
    },
  )
}

export const useSearchMeeting = (meetingId: undefined | number, searchTerms: any) => {
  const queryClient = useQueryClient()
  const axiosInstance = useAuthAxios()
  return useQuery(
    ["transcripts", searchTerms, meetingId],
    async () => {
      const response = await (
        await axiosInstance
      ).post<{
        transcripts_by_phrase: TranscriptHits[]
        total_hits: number
        meeting_hits: Record<string, number>
      }>(`/meetings/${meetingId}/transcripts_by_phrase`, {
        searchTerms,
        page_of_origin: "meeting_detail",
      })
      const { data } = response
      data.transcripts_by_phrase = data?.transcripts_by_phrase?.sort((a, b) => (a.hits.length > b.hits.length ? -1 : 1))
      return data
    },
    {
      enabled: typeof meetingId === "number" && searchTerms?.filter((term: string) => term !== "")?.length > 0,
      onSuccess: () => updateQuickstartSearched(queryClient),
    },
  )
}

export interface FetchedMeeting extends BaseMeeting {
  city: string
  county: string
  state: string
  organization_name: string
  has_viewed: boolean
}

export const useFetchMeeting = (meetingId: string, onSuccess?: (meeting: FetchedMeeting) => void) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    [`meeting_${meetingId}`],
    async () => {
      const response = await (await axiosInstance).get<{ meeting: FetchedMeeting }>(`/meetings/${meetingId}`)

      return response.data
    },
    {
      onSuccess: (response) => {
        if (onSuccess) {
          onSuccess(response.meeting)
        }
      },
    },
  )
}

export const useUpdateMeeting: any = (orgId: string) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      const convertedDate = moment(params.publishedAt)

      return (await axiosInstance).put(`/admin/organizations/${params.id}/meetings/${params.meetingId}`, {
        title: params.title,
        source_video_url: params.sourceVideoUrl,
        published_at: convertedDate?.toString(),
        duration_seconds: params.duration,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`organization_meetings_${orgId}`)
      },
    },
  )
}

export const useDeleteMeetings: any = (orgId: string) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).delete(
        `/admin/organizations/${params.orgId}/meetings/${params.meetingIds.join(",")}`,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`organization_meetings_${orgId}`)
      },
    },
  )
}

export const useNewMeeting: any = (orgId: string) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      const convertedDate = moment(params.publishedAt)
      return (await axiosInstance).post(`/admin/organizations/${orgId}/meetings`, {
        title: params.title,
        source_video_url: params.sourceVideoUrl,
        published_at: convertedDate?.toString(),
        duration_seconds: params.duration,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`organization_meetings_${orgId}`)
      },
    },
  )
}

export const useRunTranscriptionJobs: any = (orgId: string) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post(
        `/admin/organizations/${orgId}/meetings/${params?.meetingIds?.join(",")}/run_transcription_jobs`,
        params,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`organization_meetings_${orgId}`)
      },
    },
  )
}

export const useDeleteTranscriptsForMeetings: any = (orgId: string) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post(
        `/admin/organizations/${orgId}/meetings/${params?.meetingIds?.join(",")}/delete_transcripts`,
        params,
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries(`organization_meetings_${orgId}`),
    },
  )
}

export const useCalculateMetadata: any = (orgId: string) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post(
        `/admin/organizations/${orgId}/meetings/${params?.meetingIds?.join(",")}/calculate_metadata`,
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries(`organization_meetings_${orgId}`),
    },
  )
}

export const useFetchMeetingTranscripts = (meetingId?: string) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    [`transcripts_${meetingId}`],
    async () => {
      const response = await (
        await axiosInstance
      ).get<{ transcripts: TranscriptWithPerson[] }>(`/meetings/${meetingId}/transcripts`)

      return response.data
    },
    {
      enabled: meetingId !== undefined,
    },
  )
}

export const useFetchAdminMeetings: any = (page: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["admin_meetings", page], async () => {
    const response = await (await axiosInstance).get(`/admin/meetings?page=${page}`)

    return response.data
  })
}

export const useFetchSpamMeetings: any = (page: number, daysBack: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["spam_meetings", page, daysBack], async () => {
    const response = await (
      await axiosInstance
    ).get(`/admin/meetings/spam_meetings?page=${page + 1}&days_back=${daysBack}`)

    return response.data
  })
}

export const useUpdateSpam: any = (organizationId?: number | undefined) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).put(`/admin/meetings/${params.ids}`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin_meetings")
        queryClient.invalidateQueries("spam_meetings")
        queryClient.invalidateQueries(["meetings", { organization_id: organizationId?.toString() }])
        queryClient.invalidateQueries(`organization_meetings_${organizationId}`)
      },
    },
  )
}

export interface MeetingSpamCheck {
  meeting_id: number
  is_spam: boolean
  confidence_score: number
  reasoning: string
}

export const useCheckSpam = (meeting_ids: number[], custom_prompt?: string) => {
  const axiosInstance = useAuthAxios()
  if (custom_prompt?.trim() === "") custom_prompt = undefined
  return useQuery(
    ["check_spam", meeting_ids],
    async () => {
      const response = await (
        await axiosInstance
      ).get<MeetingSpamCheck[]>(`/admin/meetings/check_spam`, {
        params: { meeting_ids, custom_prompt },
      })

      return response.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
}

export const useFetchSimilarMeetings = (meetingId?: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["similar_meetings", meetingId],
    async () => {
      const response = await (await axiosInstance).get(`/meetings/${meetingId}/similar_meetings`)

      return response.data
    },
    {
      enabled: meetingId !== undefined,
    },
  )
}

export const useFetchScrapingEngine: any = (daysBack: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["scraping_engine", daysBack], async () => {
    const response = await (await axiosInstance).get(`/admin/meetings/scraping_engine?days_back=${daysBack}`)

    return response.data
  })
}

export const useCreateSimilarMeetingLog: any = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async (params: { from_meeting_id: number; to_meeting_id: number }) => {
    return (await axiosInstance).post<RubyObject>("/meetings/create_similar_meeting_log", params)
  })
}

export const useMarkViewed = (meetingId: string) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      const response = await (await axiosInstance).post(`/meetings/${meetingId}/mark_viewed`)

      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["search"])
        queryClient.invalidateQueries([`meeting_${meetingId}`])
      },
    },
  )
}

export const getMeetingBriefing = (id: number | string, onSuccess?: (data: string) => void) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["meetings", id, "briefing"],
    async () => {
      return (await (await axiosInstance).get<string>(`${prefix}/${id}/briefing`)).data
    },
    {
      onSuccess,
      enabled: Boolean(id),
    },
  )
}

export const getMeetingPersonalBriefing = (id: number | string, onSuccess?: (data: PublicBriefing[]) => void) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["meetings", id, "personalBriefing"],
    async () => {
      return (await (await axiosInstance).get<PublicBriefing[]>(`meetings/${id}/personal_briefing`)).data
    },
    {
      onSuccess,
      enabled: Boolean(id),
    },
  )
}

// this will deprecate getMeetingPersonalBriefing and getMeetingBriefing.
// TO DO: remove those after confirming they're gone and there are no regressions
export const useFetchMeetingBriefings = (
  id: unknown,
  options?: Omit<UseQueryOptions<string, unknown, PublicBriefing[], unknown[]>, "queryKey" | "queryFn">,
) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["meetings", id, "briefings"],
    async () => {
      return (await (await axiosInstance).get(`${prefix}/${id}/briefings`)).data
    },
    {
      enabled: Boolean(id),
      ...options,
    },
  )
}

export const useFetchMeetingBriefingCreditUsages = (
  id: unknown,
  options?: Omit<UseQueryOptions<string, unknown, BriefingCreditUsage[], unknown[]>, "queryKey" | "queryFn">,
) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["meetings", id, "briefing_credit_usages"],
    async () => {
      return (await (await axiosInstance).get(`${prefix}/${id}/briefing_credit_usages`)).data
    },
    {
      enabled: Boolean(id),
      ...options,
    },
  )
}

export const useFetchMeetingBriefingCreditUsagesMap = (
  id: unknown,
  options?: Omit<UseQueryOptions<string, unknown, BriefingCreditUsage[], unknown[]>, "queryKey" | "queryFn">,
) => {
  const fetchData = useFetchMeetingBriefingCreditUsages(id, options)
  if (fetchData.data) {
    const briefingCreditUsagesMap = new Map<number, BriefingCreditUsage[]>()
    fetchData.data.forEach((briefingCreditUsage) => {
      const briefingCreditUsagesList = briefingCreditUsagesMap.get(briefingCreditUsage.briefing_id)
      if (!briefingCreditUsagesList) {
        briefingCreditUsagesMap.set(briefingCreditUsage.briefing_id, [briefingCreditUsage])
      } else {
        briefingCreditUsagesMap.set(briefingCreditUsage.briefing_id, [...briefingCreditUsagesList, briefingCreditUsage])
      }
    })
    return {
      ...fetchData,
      data: briefingCreditUsagesMap,
    }
  }
  return fetchData
}

interface MeetingBriefingCostResponse {
  cost: number
}

export const getMeetingsBriefingCost = (meeting_id: number, number_of_prompts: number = 1) => {
  const axiosInstance = useAuthAxios()
  const url_path = generateMinnieURL("minnie/briefings/cost")
  return useQuery(
    ["briefing_cost", { meeting: meeting_id }],
    async () => {
      const request_url = url_path + `?meeting_id=${meeting_id}&number_of_prompts=${number_of_prompts}`
      return (await (await axiosInstance).get<MeetingBriefingCostResponse>(request_url)).data
    },
    {
      enabled: Boolean(meeting_id),
    },
  )
}

export const useCreateGeneralBriefing = (id: unknown, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()

  return useMutation(
    async () => {
      return (await axiosInstance).post(`/meetings/${id}/general_briefing`)
    },
    {
      onSuccess,
    },
  )
}

export const useCreatePersonalBriefing = (id: unknown, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { promptIds: number[] }) => {
      return (await axiosInstance).post(`/meetings/${id}/personal_briefing`, { prompt_ids: params.promptIds })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["meetings", id, "briefings"])
        queryClient.invalidateQueries(["meetings", id, "briefing_credit_usages"])
        onSuccess?.()
      },
    },
  )
}

interface MeetingOpportunity extends Opportunity {
  naics_code: NaicsCode
}
export const useFetchMeetingOpportunities = (meetingId?: unknown) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["meetings", `${meetingId}`, "opportunities"],
    async () => {
      const response = await (await axiosInstance).get<MeetingOpportunity[]>(`${prefix}/${meetingId}/opportunities`)

      return response.data
    },
    {
      enabled: meetingId !== undefined,
    },
  )
}
