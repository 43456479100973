import { useRetryBriefing } from "@api/briefings"
import { useFetchUsersMap } from "@api/users"
import { PublicBriefing } from "@interfaces/briefing"
import BriefingCard from "@src/stories/BriefingCard"
import { FC } from "react"

export const BriefingCardWithRetry: FC<{
  briefing: PublicBriefing
  ownerIds?: number[]
}> = ({ briefing, ownerIds }) => {
  const { data: usersMap } = useFetchUsersMap()
  const { mutate: retryBriefing, isLoading: retryIsLoading } = useRetryBriefing(briefing.id, briefing.meeting_id)

  return (
    <BriefingCard
      briefing={briefing}
      isLoading={retryIsLoading}
      onRetryBriefing={retryBriefing}
      ownerEmails={ownerIds?.map((id) => usersMap?.get(id)?.email || "").filter((email) => email)}
    />
  )
}
