import { useAuthAxios } from "@api/axiosInstance"
import { BriefingUrlBuilder, urlParamsToBriefingQueryObject } from "@api/briefings"
import { useIsBriefingsEnabled } from "@api/users"
import { AdminBriefing } from "@interfaces/briefing"
import { useMutation, useQuery, useQueryClient } from "react-query"

const prefix = "/admin/briefings"

export const getAdminBriefings = (params?: URLSearchParams) => {
  const axiosInstance = useAuthAxios()
  const { data: isBriefingsEnabled } = useIsBriefingsEnabled()

  const urlParams = params && urlParamsToBriefingQueryObject(params)

  return useQuery(
    ["admin-briefings", urlParams],
    async () => {
      const url = new BriefingUrlBuilder(prefix).build(params)
      const response = await (await axiosInstance).get<{ total: number; briefings: AdminBriefing[] }>(url)

      return response.data
    },
    {
      enabled: Boolean(isBriefingsEnabled),
    },
  )
}

export const getGeneratedByModelList = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["generated-by-model-list"], async () => {
    const response = await (await axiosInstance).get<string[]>(`${prefix}/generated_by_model_list`)

    return response.data
  })
}

export const useUpdateFineTuning = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async ({ briefing_ids, fine_tuning }: { briefing_ids: number[]; fine_tuning: boolean }) => {
      return (await axiosInstance).patch(`${prefix}/update_fine_tuning`, {
        ids: briefing_ids,
        use_fine_tuning: fine_tuning,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin-briefings")
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}
