import { TableRowsLoader } from "@components/shared/Table"
import { VoiceWithSpeaker } from "@interfaces/voice"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import { Dispatch, FC, SetStateAction } from "react"
import { VoiceRow } from "./VoiceRow"
import { useToast } from "@components/shared/ToastHook"

interface SpeakerIdentificationTableProps {
  data?: VoiceWithSpeaker[]
  setData: Dispatch<SetStateAction<VoiceWithSpeaker[]>>
  isLoading: boolean
}

export const SpeakerIdentificationTable: FC<SpeakerIdentificationTableProps> = ({ data, setData, isLoading }) => {
  const setToast = useToast()

  const handleUpdateVoice = (voiceId: number, newVoice: VoiceWithSpeaker) => {
    // Update the voice in the data array with the new voice
    const voiceIndex = data?.findIndex((voice) => voice.id === voiceId)
    if (voiceIndex == undefined || voiceIndex === -1 || !data) {
      setToast("Error refreshing voice data, please reload page.", 2000, undefined, "error")
      return
    }
    const newData = [...data.slice(0, voiceIndex), newVoice, ...data.slice(voiceIndex + 1)]
    setData(newData)
  }
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Voice_id</TableCell>
            <TableCell>Speaker</TableCell>
            <TableCell sx={{ minWidth: "36ch" }}>Person_id</TableCell>
            <TableCell sx={{ minWidth: "22ch" }}>Contact</TableCell>
            <TableCell sx={{ minWidth: "24ch" }}>Person Name</TableCell>
            <TableCell sx={{ minWidth: "24ch" }}>Person Title</TableCell>
            <TableCell sx={{ minWidth: "24ch" }}>Person Organization</TableCell>
            <TableCell>Update</TableCell>
            <TableCell>Verified</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? Array(10)
                .fill(0)
                .map((_, index) => (
                  <TableRow key={index}>
                    {Array(8)
                      .fill(0)
                      .map((_, index) => (
                        <TableRowsLoader key={index} />
                      ))}
                  </TableRow>
                ))
            : data?.map((voice) => <VoiceRow key={voice.id} voice={voice} handleUpdateVoice={handleUpdateVoice} />)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
