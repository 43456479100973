import { useAuthAxios } from "@api/axiosInstance"
import { ModelConfigurations, RunIdentifySpeakerOptions } from "@interfaces/speaker"
import { Voice } from "@interfaces/voice"
import { useMutation } from "react-query"

const prefix = "/admin/meetings"

interface RunIdentifySpeakerParams {
  onSuccess?: () => void
  onError?: () => void
}

export const useRunIdentifySpeakers = ({ onSuccess, onError }: RunIdentifySpeakerParams) => {
  const axiosInstance = useAuthAxios()

  return useMutation(
    async (params: RunIdentifySpeakerOptions & Partial<ModelConfigurations>) => {
      return (await axiosInstance).post<Voice[]>(`${prefix}/${params.meetingId}/identify_speakers`, {
        options: {
          dry_run: params.dryRun,
          model: params.model,
          use_fine_tuned_model: params.useFineTunedModel,
        },
      })
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: () => {
        if (onError) {
          onError()
        }
      },
    },
  )
}
