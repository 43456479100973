import { GridColDef } from "@mui/x-data-grid/models/colDef"
import { Environments, getKeyByValue, ProxyType } from "@src/interfaces/content-acquisition/responseObjectInterfaces"

export const proxyColumns: (daysBack: number) => GridColDef[] = (daysBack) => [
  {
    field: "id",
    flex: 0.1,
    headerName: "ID",
    type: "number",
  },
  { field: "name", flex: 1, headerName: "Name", editable: true },
  {
    field: "internal_ip",
    flex: 1,
    description: "Used by the Proxy Provider Downloader.",
    headerName: "Internal Ip",
    editable: true,
  },
  {
    field: "external_ip",
    flex: 1,
    description: "Unique, visible identifier shown to target domains.",
    headerName: "External Ip",
    editable: true,
  },
  { field: "zone", flex: 0.5, headerName: "Zone", editable: true },
  {
    field: "agent_type",
    flex: 0.5,
    headerName: "Type",
    editable: true,
    type: "singleSelect",
    valueOptions: Object.values(ProxyType),
  },
  {
    field: "provider",
    flex: 0.5,
    description: "Host provider for proxy",
    headerName: "Provider",
    editable: true,
  },
  {
    field: "suspended_until",
    headerName: "Suspended Globally",
    valueGetter: (value) => new Date(value as string),
    renderCell: (params) => new Date(params.value as string).toLocaleString(),
    type: "dateTime",
    flex: 1,
    editable: false, // move to true when we have a way to unsuspend
  },
  {
    field: "disabled_at",
    type: "boolean",
    flex: 0.75,
    headerName: "Disabled",
    editable: true,
    valueGetter: (value) => value && new Date(value),
    valueParser: (value) => (value ? new Date().toISOString() : null),
  },
  {
    field: "environment_type",
    flex: 1,
    headerName: "Environment Type",
    type: "singleSelect",
    valueOptions: Object.entries(Environments),
    getOptionLabel: (option: any) => option[0],
    getOptionValue: (option: any) => option[1],
    valueFormatter: (value) => getKeyByValue(Environments, value as string),
    editable: true,
  },
  {
    field: "max_connections",
    flex: 0.5,
    headerName: "Max Connections",
    type: "number",
    editable: true,
  },
  {
    field: "successRate",
    flex: 1,
    editable: false,
    headerName: `${daysBack} Day Success Rate`,
    valueGetter: (value) => {
      if (value === 0) return 0
      if (!value) return undefined
      return value * 100
    },
    valueFormatter: (value) => {
      return value !== undefined ? `${(value as number).toFixed(2)}%` : ""
    },
  },
  { field: "successCount", flex: 1, headerName: `${daysBack} Day Success Count`, editable: false },
  { field: "totalCount", flex: 1, headerName: `${daysBack} Day Total Count`, editable: false },
]
