import { FC, useState, useEffect, useContext, useRef, MutableRefObject, createContext } from "react"
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material"
import { Helmet } from "react-helmet"
import { Context } from "@src/components/MeetingDetail/Shared/Context"
import { VideoCard } from "@src/components/MeetingDetail/VideoCard"
import Tabs from "@src/components/shared/Tabs/HashTabs"
import { TranscriptsTabContent } from "@src/components/MeetingDetail/TranscriptsTab"
import { StatisticsDetailsCard } from "@src/components/MeetingDetail/StatisticsDetailsCard"
import { useIsSuperAdmin, useIsBriefingsEnabled, useIsOpportunitiesEnabled } from "@api/users"
import { SendBriefingCard } from "@src/components/MeetingDetail/SendBriefingCard"
import { TimelineAccordion } from "@src/components/MeetingDetail/TimelineAccordion"
import { MobileVideo } from "@src/components/MeetingDetail/VideoCard/MobileVideo"
import { useLocation, useParams } from "react-router-dom"
import { IMeetingRouteParams } from "@api/interfaces"
import { FetchedMeeting, useFetchMeeting } from "@api/meetings"
import { GeneralBriefingCard } from "@src/components/MeetingDetail/BriefingTab"
import { useMarkViewed } from "@api/meetings"
import { SpeakerIdentificationComponent } from "@components/Admin/Meetings/SpeakerIdentificationTable"
import { useFetchCurrentUser } from "@api/users"
import { PersonalBriefing } from "@src/components/MeetingDetail/PersonalBriefingTab"
import { Opportunities } from "@src/components/MeetingDetail/OpportunitiesTab"
import Loading from "@src/components/shared/Loading"

export interface MeetingPageContextProps {
  meeting: FetchedMeeting
  setMeeting: (meeting: FetchedMeeting) => void
  searchTermsInputRef: MutableRefObject<HTMLInputElement>
}

export const MeetingPageContext = createContext<MeetingPageContextProps>({
  meeting: {} as FetchedMeeting,
  setMeeting: () => {
    /* placeholder for typescript */
  },
  searchTermsInputRef: {} as MutableRefObject<HTMLInputElement>,
})

export const MeetingPage: FC = () => {
  const { data: isSuperAdmin } = useIsSuperAdmin()
  const { setMeeting } = useContext(MeetingPageContext)
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data: meetingData, isLoading } = useFetchMeeting(meetingId, (data) => {
    setMeeting(data)
  })
  const { data: briefingsEnabled } = useIsBriefingsEnabled()
  const { data: opportunitiesEnabled } = useIsOpportunitiesEnabled()

  const { data: currentUser } = useFetchCurrentUser()

  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up("md"))

  const searchString = useLocation().search
  const params = new URLSearchParams(searchString)
  const startTimeSeconds = params.get("startTimeSeconds")
  const { mutate } = useMarkViewed(meetingId)

  const [state, setState] = useState({
    progress: [0, 0],
    videoStartTime: startTimeSeconds
      ? { startTime: parseInt(startTimeSeconds), change: false }
      : { startTime: 0, change: false },
    playingVideo: false,
    isReady: false,
  })
  const searchTermsInputRef = useRef<HTMLInputElement>({} as HTMLInputElement)

  useEffect(() => {
    mutate()
    window.scrollTo(0, 0)
  }, [])

  if (isLoading || !meetingData) {
    return <Loading />
  }

  const tabs = [
    {
      hash: "",
      label: "Transcripts",
      component: <TranscriptsTabContent />,
    },
    {
      hash: "briefing",
      label: "Briefing",
      component: <GeneralBriefingCard />,
    },
  ]

  if (briefingsEnabled) {
    tabs.push({
      hash: "personal-briefing",
      label: "Personal Briefing",
      component: <PersonalBriefing />,
    })
  }

  if (opportunitiesEnabled) {
    tabs.push({
      hash: "opportunities",
      label: "Opportunities",
      component: <Opportunities id={meetingId} />,
    })
  }

  return (
    <Context.Provider value={{ state, setState }}>
      <MeetingPageContext.Provider
        value={{
          meeting: meetingData.meeting || ({} as FetchedMeeting),
          setMeeting,
          searchTermsInputRef,
        }}
      >
        <Helmet>
          <title>{`Cloverleaf.AI | ${meetingData.meeting.title || "Meeting"}`}</title>
        </Helmet>
        <Box
          marginBottom={{
            xs: `${window.innerWidth * 0.5625 + 16}px`,
            md: "0",
          }}
        >
          <Stack direction="row">
            <Stack direction="column" padding={2} spacing={2} width={{ xs: "100%", md: "50%" }}>
              <VideoCard />
              {!isMd && <Tabs gap={1} tabs={tabs} />}
              <StatisticsDetailsCard />
              {currentUser?.permissions?.includes("speaker_identification") && <SpeakerIdentificationComponent />}
              {isSuperAdmin && <SendBriefingCard />}
              {process.env.REACT_APP_GOOGLE_ENV !== "production" && <TimelineAccordion />}
            </Stack>
            {isMd && <Tabs gap={1} tabs={tabs} width="50%" padding={2} />}
          </Stack>
        </Box>
        <MobileVideo />
      </MeetingPageContext.Provider>
    </Context.Provider>
  )
}
