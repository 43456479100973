import { FC, useEffect, useState } from "react"
import { Button, Stack, Card, CardContent, Select, MenuItem } from "@mui/material"
import { getMeetingVoices } from "@api/admin/meetings"
import { useRunIdentifySpeakers } from "@api/admin/speaker"
import { useToast } from "@components/shared/ToastHook"
import { useParams } from "react-router-dom"
import { IMeetingRouteParams } from "@api/interfaces"
import { SpeakerIdentificationTable } from "./SpeakerTable"
import {
  TEST_SPEAKER_IDENTIFICATION_MODAL_KEY,
  TestSpeakerIdentificationModal,
  TestSpeakerIdentificationModalData,
} from "./TestSpeakerIdentificationModal"
import { useOpenModalKey, useSetData } from "@components/shared/OpenModalHook"
import { VoiceWithSpeaker } from "@interfaces/voice"
import { LLMModelOptions } from "@interfaces/speaker"

export const SpeakerIdentificationComponent: FC = () => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const toast = useToast()
  const { data: voiceData, isLoading: voiceDataLoading, isRefetching } = getMeetingVoices(meetingId)
  const [modelChoice, setModelChoice] = useState<LLMModelOptions>("gemini-1.5-flash-002")
  const {
    mutate: runIdentifySpeakers,
    isLoading: speakerIdentificationLoading,
    data: runIndentifyResponse,
  } = useRunIdentifySpeakers({
    onSuccess: () => {
      toast("Speaker identification Successful")
    },
    onError: () => {
      toast("Speaker identification Failed")
    },
  })

  const canUseFineTunedModel = () => {
    return modelChoice === "gemini-1.5-flash-002"
  }

  const handleSpeakerIdentification = () => {
    toast("Speaker identification started")
    runIdentifySpeakers({
      meetingId: parseInt(meetingId),
      model: modelChoice,
      useFineTunedModel: canUseFineTunedModel(),
    })
  }

  const [tableData, setTableData] = useState<VoiceWithSpeaker[]>([])

  useEffect(() => {
    if (voiceData) {
      setTableData(voiceData)
    }
  }, [voiceData])

  useEffect(() => {
    if (runIndentifyResponse) {
      setTableData((prevTableData) => {
        const updatedTableData = prevTableData.map((entry) => {
          const updatedVoice = runIndentifyResponse.data.find((voice) => voice.id === entry.id)
          return updatedVoice ? { ...updatedVoice, speaker: entry.speaker } : entry
        })
        return updatedTableData
      })
    }
  }, [runIndentifyResponse])

  const [modalOpen, setModalOpen] = useState(false) // Control rendering to ensure data is loaded before opening modal

  const openTestModal = useOpenModalKey(TEST_SPEAKER_IDENTIFICATION_MODAL_KEY)

  const setDataOnModal = useSetData<TestSpeakerIdentificationModalData>()

  const handleOpenModal = () => {
    setDataOnModal({ meetingId })
    openTestModal()
    setModalOpen(true)
  }

  return (
    <>
      <Card>
        <CardContent>
          <Stack direction="row" marginBottom={1} justifyContent="space-between" width="100%">
            <Stack direction="row" spacing={2}>
              <Button variant="contained" onClick={handleSpeakerIdentification} disabled={speakerIdentificationLoading}>
                Run Speaker Identification
              </Button>
              <Select
                size="small"
                value={modelChoice}
                onChange={(event) => setModelChoice(event.target.value as LLMModelOptions)}
                disabled={speakerIdentificationLoading}
              >
                <MenuItem value="gemini-1.5-pro-002">gemini-1.5-pro-002</MenuItem>
                <MenuItem value="gemini-1.5-flash-002">gemini-1.5-flash-002</MenuItem>
              </Select>
            </Stack>
            <Button variant="contained" onClick={handleOpenModal} disabled={speakerIdentificationLoading}>
              Test Run
            </Button>
          </Stack>
          <SpeakerIdentificationTable
            data={tableData}
            setData={setTableData}
            isLoading={voiceDataLoading || speakerIdentificationLoading || isRefetching}
          />
        </CardContent>
      </Card>
      {modalOpen && <TestSpeakerIdentificationModal />}
    </>
  )
}
