import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query"
import { useAuth0 } from "@auth0/auth0-react"
import { useAuthAxios } from "./axiosInstance"
import { ApiErrorResponse, useSmartUpdate } from "./api"
import { QuickstartData, SimpleUser, UserCreditUsages } from "../interfaces/user"
import { SearchLog } from "./interfaces"
import { Campaign } from "../components/Home/interface"
import { AxiosError } from "axios"
import { useContext } from "react"
import {
  InitialSystemUserContextDetails,
  SystemUserContext,
  SystemUserContextDetails,
} from "@src/providers/userContextProvider"

const prefix = "/users"
const dummyQueryResult = {
  data: undefined,
  error: null,
  isError: false,
  isIdle: false,
  isLoading: false,
  isLoadingError: false,
  isRefetchError: false,
  isSuccess: true,
  isFetched: true,
  isFetching: false,
  status: "success",
}
export const useFetchCurrentUserEmail = () => {
  const { getIdTokenClaims } = useAuth0()
  return useQuery(
    ["current_user_email"],
    async () => {
      const token = await getIdTokenClaims()
      if (!token) {
        throw "Token is undefined"
      }
      return token.email
    },
    {
      notifyOnChangeProps: "tracked",
    },
  )
}

export const useFetchTrialStatus = (enabled = true) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["trial_status"],
    async () => {
      const response = await (await axiosInstance).get(`${prefix}/trial_status`)
      return response.data
    },
    {
      enabled: enabled,
    },
  )
}

export const useFetchUsers = () => {
  const axiosInstance = useAuthAxios()
  const { data } = useFetchCurrentUser()

  return useQuery(
    ["users"],
    async () => {
      const response = await (await axiosInstance).get<SimpleUser[]>(prefix)

      return response.data
    },
    {
      enabled: data?.user_organization_id !== null,
    },
  )
}

export const useFetchUsersMap = () => {
  const fetchData = useFetchUsers()
  if (fetchData.data) {
    return {
      ...fetchData,
      data: new Map(fetchData.data.map((user) => [user.id, user])),
    }
  }
  return fetchData
}

export const useCreateUser = (onSuccess?: () => void, onError?: (errorMessage: string) => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()
  const smartUpdate = useSmartUpdate(queryClient)

  return useMutation(
    async (params: {
      email: string
      roles: string[]
      requires_signals_notifications: boolean
      requires_account_summary_notifications: boolean
    }) => {
      return (await axiosInstance).post(`${prefix}`, params)
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["users", "credit_usages"])
        smartUpdate(response)
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: (error: AxiosError<ApiErrorResponse>) => {
        if (onError) {
          onError(error.response?.data?.errorMessage || "An uknown error occurred")
        }
      },
    },
  )
}

export const useDeleteUsers = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (ids: number[]) => {
      return (await axiosInstance).delete(`${prefix}/${ids.join(",")}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useUpdateUsersNotifications = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async ({
      ids,
      requires_signals_notifications,
      requires_account_summary_notifications,
    }: {
      ids: number[]
      requires_signals_notifications?: boolean
      requires_account_summary_notifications?: boolean
    }) => {
      return (await axiosInstance).put(`${prefix}/notifications`, {
        ids,
        requires_signals_notifications,
        requires_account_summary_notifications,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useUpdateUsersRoles = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { ids: number[]; roles: Record<string, boolean> }) => {
      return (await axiosInstance).post(`${prefix}/roles`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useFetchCurrentUser = (enabled = true): SystemUserContextDetails => {
  const currentContext = enabled ? useContext(SystemUserContext) : InitialSystemUserContextDetails
  return currentContext
}

export const useFetchQuickstart = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["user_quickstart"], async () => {
    const response = await (await axiosInstance).get<QuickstartData>(`${prefix}/quickstart`)
    return response.data
  })
}

export const updateQuickstartSearched = (queryClient: QueryClient) => {
  queryClient.setQueryData(["user_quickstart"], (oldData: unknown) => {
    if (!oldData || typeof oldData !== "object") {
      return oldData
    } else {
      return {
        has_campaign: false,
        has_subscribed: false,
        ...oldData,
        has_searched: true,
      }
    }
  })
}

export const updateUserQuickstart = (queryClient: QueryClient) => {
  queryClient.setQueryData(["user_quickstart"], (oldData: unknown) => {
    if (!oldData || typeof oldData !== "object") {
      return oldData
    } else {
      return {
        has_searched: false,
        has_subscribed: false,
        ...oldData,
        has_campaign: true,
      }
    }
  })
}

export const updateQuickstartSubscribed = (queryClient: QueryClient) => {
  queryClient.setQueryData(["user_quickstart"], (oldData: unknown) => {
    if (!oldData || typeof oldData !== "object") {
      return oldData
    } else {
      return {
        has_searched: false,
        has_campaign: false,
        ...oldData,
        has_subscribed: true,
      }
    }
  })
}

export const useUpdateUserOnboarding = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()
  const { data: email } = useFetchCurrentUserEmail()

  return useMutation(
    async (params: { industry_id: number; industry_other?: string; use_case: string; use_case_other: string }) => {
      return (await axiosInstance).post(`${prefix}/onboarding`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users", { email }])
        queryClient.invalidateQueries(["users"])
        queryClient.invalidateQueries(["recommended_campaigns"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useIsAdmin = () => {
  const { data } = useFetchCurrentUser()
  return {
    ...dummyQueryResult,
    data: Boolean(data && data.roles.some((role) => role.name === "Admin")),
  }
}

export const useIsFreeUser = () => {
  const { data } = useFetchCurrentUser()
  return {
    ...dummyQueryResult,
    data: Boolean(data && data.roles.length === 1 && data.roles[0].name === "Free"),
  }
}

export const useIsPaidUser = () => {
  const { data } = useFetchCurrentUser()
  return {
    ...dummyQueryResult,
    data: Boolean(data && (data.roles.length > 1 || (data.roles[0].name !== "Free" && data.roles.length === 1))),
  }
}

export const useIsSuperAdmin = () => {
  const { data } = useFetchCurrentUser()

  return {
    ...dummyQueryResult,
    data: Boolean(data && data.roles.some((role) => role.name === "Super Admin")),
  }
}

export const useIsContractor = () => {
  const { data } = useFetchCurrentUser()
  return {
    ...dummyQueryResult,
    data: Boolean(
      data &&
        data.roles.some((role) => {
          return role.name === "Contractor"
        }),
    ),
  }
}

export const useIsBriefingsEnabled = () => {
  const { data } = useFetchCurrentUser()
  return {
    ...dummyQueryResult,
    data: data?.briefings_enabled,
  }
}

export const useIsSalesforceEnabled = () => {
  const { data } = useFetchCurrentUser()
  return {
    ...dummyQueryResult,
    data: data?.salesforce_enabled,
  }
}

export const useIsSpeakerIdentificationEnabled = () => {
  const { data } = useFetchCurrentUser()
  return {
    ...dummyQueryResult,
    data: data?.speaker_identification_enabled,
  }
}

export const useIsOpportunitiesEnabled = () => {
  const { data } = useFetchCurrentUser()
  return {
    ...dummyQueryResult,
    data: data?.opportunities_enabled,
  }
}

export const useFetchUserSearches: any = (userId: any) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["user_searches", userId], async () => {
    const response = await (await axiosInstance).get<SearchLog>(`/admin/users/${userId}/search_logs`)

    return response.data
  })
}

export const useFetchRecommendedCampaigns = () => {
  const axiosInstance = useAuthAxios()

  return useQuery<Campaign[]>(["recommended_campaigns"], async () => {
    const response = await (await axiosInstance).get(`${prefix}/recommended_campaigns`)

    return response.data
  })
}

export const getAbout = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["users", "about"], async () => {
    return (await (await axiosInstance).get<Record<string, unknown> | null>(`${prefix}/about`)).data
  })
}

export const useSetAbout = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { formData: unknown }) => {
      return (await (await axiosInstance).post(`${prefix}/about`, params)).data
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["users", "about"])
      },
    },
  )
}

export const useFetchCurrentUserCreditUsages = () => {
  const axiosInstance = useAuthAxios()

  const { data: email } = useFetchCurrentUserEmail()
  return useQuery(["users", { email }, "credit_usages"], async () => {
    return (await (await axiosInstance).get<UserCreditUsages>(`${prefix}/my_credit_usages`)).data
  })
}

export const useFetchUsersCreditUsages = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["users", "credit_usages"], async () => {
    return (await (await axiosInstance).get<[UserCreditUsages]>(`${prefix}/credit_usages`)).data
  })
}

export const useUpdateCreditLimit = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { ids: number[]; credit_limit: number }) => {
      return (await axiosInstance).post(`${prefix}/credit_limits`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}
