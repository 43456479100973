import { useRunIdentifySpeakers } from "@api/admin/speaker"
import { ModelConfigurations } from "@interfaces/speaker"
import { useCloseModalKey, useData, useModalKey } from "@components/shared/OpenModalHook"
import { useToast } from "@components/shared/ToastHook"
import { Box, Button, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material"
import { FC, useState } from "react"
import { CustomModelConfig } from "./CustomModelConfig"
import { SpeakerIdentificationTable } from "./SpeakerTable"
import { VoiceWithSpeaker } from "@interfaces/voice"

export const TEST_SPEAKER_IDENTIFICATION_MODAL_KEY = "TEST_SPEAKER_IDENTIFICATION_MODAL_KEY"

export interface TestSpeakerIdentificationModalData {
  meetingId: string
}

export const TestSpeakerIdentificationModal: FC = () => {
  const open = useModalKey(TEST_SPEAKER_IDENTIFICATION_MODAL_KEY)
  const closeModal = useCloseModalKey(TEST_SPEAKER_IDENTIFICATION_MODAL_KEY)
  const modalData = useData<TestSpeakerIdentificationModalData>()! // Assert data is not undefined
  const toast = useToast()
  const [data1, setData1] = useState<VoiceWithSpeaker[]>([])
  const [data2, setData2] = useState<VoiceWithSpeaker[]>([])
  const [runCustomModelConfig1, setRunCustomModelConfig1] = useState<ModelConfigurations>({
    model: "gemini-1.5-flash-002",
    maxTokenSizePerChunk: 15000,
    useFineTunedModel: true,
  })

  const [runCustomModelConfig2, setRunCustomModelConfig2] = useState<ModelConfigurations>({
    model: "gemini-1.5-pro-002",
    maxTokenSizePerChunk: 15000,
    useFineTunedModel: false,
  })

  const {
    mutate: runIdentifySpeakers1,
    isLoading: speakerIdentificationLoading1,
    data: response1,
  } = useRunIdentifySpeakers({
    onSuccess: () => {
      toast("Speaker identification Test 1 Successful")
      setData1(response1?.data.map((voice) => ({ ...voice, speaker: undefined })) || [])
    },
    onError: () => {
      toast("Speaker identification Test 1 Failed")
    },
  })

  const {
    mutate: runIdentifySpeakers2,
    isLoading: speakerIdentificationLoading2,
    data: response2,
  } = useRunIdentifySpeakers({
    onSuccess: () => {
      toast("Speaker identification Test 2 Successful")
      setData2(response2?.data.map((voice) => ({ ...voice, speaker: undefined })) || [])
    },
    onError: () => {
      toast("Speaker identification Test 2 Failed")
    },
  })

  return (
    <Dialog open={open} onClose={closeModal} fullWidth maxWidth="xl">
      <DialogTitle>Test Speaker Identification</DialogTitle>
      <DialogContent sx={{ overflowX: "hidden" }}>
        <Stack direction="row" spacing={1} marginBottom={1} width={"100%"}>
          <Box sx={{ flex: "1 1 0", overflow: "hidden" }}>
            <CustomModelConfig modelOptions={runCustomModelConfig1} setModelOptions={setRunCustomModelConfig1} />
            <Button
              variant="contained"
              onClick={() =>
                runIdentifySpeakers1({
                  ...runCustomModelConfig1,
                  dryRun: true,
                  meetingId: parseInt(modalData.meetingId),
                })
              }
              disabled={speakerIdentificationLoading1}
            >
              Run Speaker Identification
            </Button>
            <SpeakerIdentificationTable data={data1} setData={setData1} isLoading={speakerIdentificationLoading1} />
          </Box>

          <Box sx={{ flex: "1 1 0", overflow: "hidden" }}>
            <CustomModelConfig modelOptions={runCustomModelConfig2} setModelOptions={setRunCustomModelConfig2} />
            <Button
              variant="contained"
              onClick={() =>
                runIdentifySpeakers2({
                  ...runCustomModelConfig2,
                  dryRun: true,
                  meetingId: parseInt(modalData.meetingId),
                })
              }
              disabled={speakerIdentificationLoading2}
            >
              Run Speaker Identification
            </Button>
            <SpeakerIdentificationTable data={data2} setData={setData2} isLoading={speakerIdentificationLoading2} />
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
