import { FC, useEffect, useMemo, useState } from "react"
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowModes,
  GridRowModesModel,
  GridSlots,
  useGridApiRef,
} from "@mui/x-data-grid"
import {
  useCreateProxy,
  useFetchProxies,
  useDeleteProxy,
  useUpdateProxy,
} from "@src/api/admin/content-acquisition/proxies"
import { theme } from "@src/theme"
import { useFetchContentAcquisitionJobs } from "@src/api/admin/content-acquisition/jobs"
import { fetchJobsByDaysBackToToday } from "@src/interfaces/content-acquisition/query/jobs"
import { Job } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import {
  CrudActions,
  handleCancelClick,
  handleDeleteClick,
  handleEditClick,
  handleRowEditStop,
  handleRowModesModelChange,
  handleSaveClick,
  processRowUpdate,
} from "../../DataGrid/editing"
import { Proxy } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import { AddConfigToTable } from "./AddConfigToTable"
import { proxyColumns } from "./columns"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import { TextField } from "@mui/material"

export const ProxyTable: FC = () => {
  const apiRef = useGridApiRef()
  const [daysBack, setDaysBack] = useState(1)
  const { data: proxies, isLoading: proxiesAreLoading } = useFetchProxies()
  const jobsParams = useMemo(() => fetchJobsByDaysBackToToday(daysBack), [daysBack])
  const { data: jobsData, isLoading: jobsAreLoading } = useFetchContentAcquisitionJobs(jobsParams)
  const [rows, setRows] = useState<Proxy[]>([])
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

  const { mutateAsync: createProy } = useCreateProxy()
  const { mutateAsync: deleteProxy } = useDeleteProxy()
  const { mutateAsync: updateProxy } = useUpdateProxy()

  const crudActions: CrudActions = {
    delete: async (id: number) => deleteProxy(id),
    update: async (row: Proxy) => updateProxy(row),
    create: async (row: Proxy) => createProy(row),
  }

  const actionColumns: GridColDef[] = [
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(row, rowModesModel, setRowModesModel)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id, rowModesModel, setRowModesModel, rows, setRows)}
              color="inherit"
            />,
          ]
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id, rowModesModel, setRowModesModel)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id, crudActions, apiRef)}
            color="inherit"
          />,
        ]
      },
    },
  ]

  const proxySuccessTotal = (jobs: Job[]) => {
    const totals = jobs.reduce(
      (acc, job) => {
        const proxyId = job?.proxy_id
        if (!proxyId) return acc
        if (!acc[proxyId]) acc[proxyId] = { total: 0, success: 0 }

        acc[proxyId].total++
        if (job.success) acc[proxyId].success++

        return acc
      },
      {} as { [key: string]: { total: number; success: number } },
    )

    return totals
  }

  useEffect(() => {
    if (!proxies || !jobsData) return
    if (!jobsData) return setRows(proxies)

    const successTotals = proxySuccessTotal(jobsData.jobs)
    setRows(
      proxies.map((proxy) => {
        if (successTotals[proxy.id]) {
          const success = successTotals[proxy.id].success
          const total = successTotals[proxy.id].total
          const percentage = total === 0 ? 0 : success / total
          return { ...proxy, ...{ successRate: percentage, totalCount: total, successCount: success } }
        } else {
          return proxy
        }
      }),
    )
  }, [proxies, jobsData?.jobs])

  return (
    <>
      <TextField
        id="outlined-number"
        label="Days Back for Success Rate"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        value={daysBack}
        onChange={(e) => setDaysBack(Number(e.target.value))}
      />
      <DataGrid
        autoHeight
        initialState={{
          columns: { columnVisibilityModel: { suspended_until: false } },
          pagination: { paginationModel: { pageSize: 25 } },
          sorting: { sortModel: [{ field: "successRate", sort: "asc" }] },
        }}
        sx={{
          '.MuiDataGrid-booleanCell[data-value="true"]': {
            color: theme.palette.success.main,
          },
          '.MuiDataGrid-booleanCell[data-value="false"]': {
            color: theme.palette.error.main,
          },
        }}
        density="compact"
        editMode="row"
        loading={proxiesAreLoading || jobsAreLoading}
        onRowModesModelChange={handleRowModesModelChange(setRowModesModel)}
        onRowEditStop={handleRowEditStop}
        rows={rows}
        processRowUpdate={(newRow: Proxy, oldRow: Proxy) =>
          processRowUpdate(newRow, oldRow, rows, setRows, crudActions)
        }
        onProcessRowUpdateError={(_error) => console.log("Error updating row")} // replace with a toast
        rowModesModel={rowModesModel}
        apiRef={apiRef}
        columns={[...proxyColumns(daysBack), ...actionColumns].map((column) => ({
          ...column,
          headerClassName: "super-app-theme--header",
        }))}
        slots={{ toolbar: AddConfigToTable as GridSlots["toolbar"] }}
        slotProps={{ toolbar: { setRows, setRowModesModel, rows } }}
      />
    </>
  )
}
