import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowModes,
  GridRowModesModel,
  GridSlots,
  useGridApiRef,
} from "@mui/x-data-grid"
import { domainColumns } from "./columns"
import { FC, useEffect, useMemo, useState } from "react"
import { theme } from "@src/theme"
import { useFetchContentAcquisitionJobs } from "@src/api/admin/content-acquisition/jobs"
import {
  useCreateDomain,
  useDeleteDomain,
  useFetchDomains,
  useUpdateDomain,
} from "@src/api/admin/content-acquisition/domains"
import { Domain } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import {
  CrudActions,
  handleCancelClick,
  handleDeleteClick,
  handleEditClick,
  handleRowEditStop,
  handleRowModesModelChange,
  handleSaveClick,
  processRowUpdate,
} from "../../DataGrid/editing"
import { AddConfigToTable } from "./AddConfigToTable"
import { subDays } from "date-fns"
import { CommonUrlParams } from "@interfaces/content-acquisition/requestParams"
import { toDomainRows } from "./function"
import { TextField } from "@mui/material"

export const DomainTable: FC<{ setSelectedRow: (domain: Domain | undefined) => void }> = ({ setSelectedRow }) => {
  const apiRef = useGridApiRef()

  const { mutateAsync: deleteDomain } = useDeleteDomain()
  const { mutateAsync: updateDomain } = useUpdateDomain()
  const { mutateAsync: createDomain } = useCreateDomain()
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const [rows, setRows] = useState<Domain[]>([])
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  })
  const [daysBack, setDaysBack] = useState(1)

  const domainSearchParams: CommonUrlParams = {
    associations: ["proxies_scraped_domains_configs"],
  }

  const { data: domains, isLoading: domainsAreLoading } = useFetchDomains(domainSearchParams)

  const jobSearchParams = useMemo<CommonUrlParams>(
    () => ({
      dateFilters: [
        {
          attribute: "started_at",
          dateRange: [subDays(new Date(), daysBack + 1), new Date()],
          filterBy: "in_range",
        },
      ],
      fields: ["success", "proxy_id", "domain_id"],
      attributeFilters: { exclude_by_error_type: "garbage_urls" },
    }),
    [daysBack],
  )

  const { data: jobsData, isLoading: jobsAreLoading } = useFetchContentAcquisitionJobs(jobSearchParams)

  const jobSuccessTotals = useMemo(() => {
    const totals = jobsData?.jobs?.reduce(
      (acc, job) => {
        if (!job?.domain_id) return acc
        if (!acc[job.domain_id]) acc[job.domain_id] = { total: 0, success: 0 }

        acc[job.domain_id].total++
        if (job.success) acc[job.domain_id].success++

        return acc
      },
      {} as { [key: string]: { total: number; success: number } },
    )
    return totals
  }, [jobsData])

  useEffect(() => {
    if (domains) {
      const domainRows = domains.map((domain) => toDomainRows(domain, jobSuccessTotals))
      setRows(domainRows)
    }
  }, [domains, jobSuccessTotals])

  const crudActions: CrudActions = {
    delete: async (id: number) => deleteDomain(id),
    update: async (row: Domain) => updateDomain(row),
    create: async (row: Domain) => createDomain(row),
  }

  const actionColumns: GridColDef[] = [
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{ color: "primary.main" }}
              onClick={handleSaveClick(row, rowModesModel, setRowModesModel)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id, rowModesModel, setRowModesModel, rows, setRows)}
              color="inherit"
            />,
          ]
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id, rowModesModel, setRowModesModel)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id, crudActions, apiRef)}
            color="inherit"
          />,
        ]
      },
    },
  ]

  return (
    <>
      <TextField
        id="outlined-number"
        label="Days Back for Success Rate"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        value={daysBack}
        onChange={(e) => setDaysBack(Number(e.target.value))}
      />
      <DataGrid
        autoHeight
        sx={{
          '.MuiDataGrid-booleanCell[data-value="true"]': { color: theme.palette.success.main },
          '.MuiDataGrid-booleanCell[data-value="false"]': { color: "red" },
        }}
        editMode="row"
        density="compact"
        loading={domainsAreLoading && jobsAreLoading}
        rows={rows}
        columns={[
          ...domainColumns(daysBack).map((column) => ({ ...column, headerClassName: "super-app-theme--header" })),
          ...actionColumns,
        ]}
        onRowClick={(selection) => {
          if (rowModesModel[selection.row.id]?.mode === GridRowModes.Edit) return
          setSelectedRow(selection.row as Domain)
        }}
        processRowUpdate={(newRow: Domain, oldRow: Domain) =>
          processRowUpdate(newRow, oldRow, rows, setRows, crudActions)
        }
        onProcessRowUpdateError={(_error) => console.log("Error updating row")} // replace with a toast
        rowModesModel={rowModesModel}
        apiRef={apiRef}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        onRowEditStop={handleRowEditStop}
        onRowModesModelChange={handleRowModesModelChange(setRowModesModel)}
        slots={{
          toolbar: AddConfigToTable as GridSlots["toolbar"],
        }}
        slotProps={{ toolbar: { setRows, setRowModesModel, rows } }}
      />
    </>
  )
}
