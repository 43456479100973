import { FC, useEffect, useState } from "react"
import { VoiceVerificationStatusMap, VoiceVerificationStatus, VoiceWithSpeaker } from "@interfaces/voice"
import { useFetchPersons } from "@api/admin/persons"
import { useUnverifyVoice, useVerifyVoice } from "@api/admin/voices"
import {
  Autocomplete,
  createFilterOptions,
  MenuItem,
  Select,
  Skeleton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { PersonCells } from "./PersonCells"
import { useLocation, Link } from "react-router-dom"
import { useDebouncedCallback } from "use-debounce"
import { Person } from "@interfaces/person"
import { AdminContact } from "@interfaces/contact"
import { useToast } from "@components/shared/ToastHook"

interface VoiceRowParams {
  voice: VoiceWithSpeaker
  handleUpdateVoice: (voiceId: number, newVoice: VoiceWithSpeaker) => void
}

export const VoiceRow: FC<VoiceRowParams> = ({ voice, handleUpdateVoice }) => {
  const [debouncedTextValue, setDebouncedTextValue] = useState("")
  const [person, setPerson] = useState<Person<AdminContact> | undefined>(voice.person)

  useEffect(() => {
    setPerson(voice.person)
  }, [voice.person])

  const setToast = useToast()

  const onSuccessVerify = (verification_status: VoiceVerificationStatus) => {
    handleUpdateVoice(voice.id, { ...voice, verification_status: verification_status })
    setToast(`Voice ${voice.id} changed to verification status of ${verification_status}`, 2000, undefined, "info")
  }

  const onSuccessUnverify = () => {
    handleUpdateVoice(voice.id, { ...voice, verification_status: VoiceVerificationStatus.Unverified })
    setToast(
      `Voice ${voice.id} changed to verification status of ${VoiceVerificationStatus.Unverified}`,
      2000,
      undefined,
      "info",
    )
  }

  const { mutate: verifyVoice, isLoading: verifyIsLoading } = useVerifyVoice(onSuccessVerify)

  const { mutate: unverifyVoice, isLoading: unVerifyIsLoading } = useUnverifyVoice(onSuccessUnverify)

  const debouncedUpdateSearchTerm = useDebouncedCallback((value) => {
    setDebouncedTextValue(value)
  }, 500)

  const { data: personData, isLoading: personDataLoading } = useFetchPersons(
    new URLSearchParams({ name: debouncedTextValue || "", count: "25" }),
    false,
    false,
    Boolean(debouncedTextValue && !person),
  )

  const newURLParams = new URLSearchParams(useLocation().search)
  newURLParams.set("voice-id", voice.id.toString())

  return (
    <TableRow>
      <TableCell>
        <Link
          to={{
            search: newURLParams.toString(),
          }}
        >
          {voice.id}
        </Link>
      </TableCell>
      <TableCell>
        <Link
          to={{
            search: newURLParams.toString(),
          }}
        >
          {voice.speaker}
        </Link>
      </TableCell>
      <TableCell>
        <Autocomplete
          onInputChange={(_event, value: string) => debouncedUpdateSearchTerm(value)}
          value={person || null}
          onChange={(_event, value) => {
            setPerson(value ?? undefined)
          }}
          loading={personDataLoading}
          renderInput={(params) => <TextField {...params} label="Person" size="small" />}
          options={personData?.persons ?? []}
          filterOptions={createFilterOptions({ limit: 25 })}
          placeholder="Search by name"
          loadingText="Loading..."
          noOptionsText={"No matching persons"}
          getOptionLabel={(option: Person<AdminContact>) => {
            return `${option.id}: ${option.name}, ${option.title}${option.organization ? `, ${option.organization}` : ""}`
          }}
        />
      </TableCell>
      <TableCell>
        {person?.contact ? (
          <Typography variant="body1">
            {person.contact.first_name} {person.contact.last_name}
          </Typography>
        ) : (
          <Typography variant="body1">No contact</Typography>
        )}
      </TableCell>
      <PersonCells voice={voice} handleUpdateVoice={handleUpdateVoice} person={person} contactData={person?.contact} />
      <TableCell align="center">
        {verifyIsLoading || unVerifyIsLoading ? (
          <Skeleton variant="rounded" width={24} height={24} sx={{ margin: "auto" }} />
        ) : (
          <Select
            value={voice.verification_status}
            onChange={(event) => {
              if (
                [VoiceVerificationStatus.HumanVerified, VoiceVerificationStatus.MachineVerified].includes(
                  event.target.value as VoiceVerificationStatus,
                )
              ) {
                verifyVoice({ id: voice.id, verification_status: event.target.value as VoiceVerificationStatus })
              }
              if (event.target.value === VoiceVerificationStatus.Unverified) {
                unverifyVoice({ id: voice.id })
              }
            }}
            fullWidth
          >
            {Object.entries(VoiceVerificationStatusMap).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        )}
      </TableCell>
    </TableRow>
  )
}
