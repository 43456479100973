import WarningIcon from "@mui/icons-material/Warning"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useCloseModalKey, useModalKey } from "../OpenModalHook"

export const briefingAlreadyCreatedModalKey = "BRIEFING_ALREADY_CREATED_MODAL"

export interface BriefingAlreadyCreatedModalProps {
  briefingNames: string[]
  onConfirm: () => void
}

export const BriefingAlreadyCreatedModal: FC<BriefingAlreadyCreatedModalProps> = ({ briefingNames, onConfirm }) => {
  const open = useModalKey(briefingAlreadyCreatedModalKey)
  const closeModalKey = useCloseModalKey(briefingAlreadyCreatedModalKey)

  const onConfirmHandler = () => {
    onConfirm()
    closeModalKey()
  }

  const briefingString =
    briefingNames.length > 1
      ? `You have already created briefings: ${briefingNames.join(", ")} for this meeting`
      : `You have already created a ${briefingNames[0]} briefing for this meeting`

  return (
    <Dialog open={open} onClose={closeModalKey} maxWidth="sm">
      <DialogTitle>
        <Stack direction="row">
          <Box display="flex" alignItems="center" marginRight={1}>
            <WarningIcon />
          </Box>
          Warning
        </Stack>
      </DialogTitle>
      <DialogContent>{briefingString}. Are you sure you want to continue?</DialogContent>
      <DialogActions>
        <Button onClick={closeModalKey}>No</Button>
        <Button variant="contained" onClick={onConfirmHandler}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
