import { Stack } from "@mui/material"
import { FC, useContext } from "react"
import { ContactsTable } from "@src/components/shared/ContactsTable"
import { MeetingPageContext } from "@pages/MeetingPage"

export const ContactsTableContainer: FC = () => {
  const { meeting } = useContext(MeetingPageContext)

  return (
    <Stack paddingTop={2} maxHeight={400} overflow="auto">
      <ContactsTable organizationId={meeting.organization_id} />
    </Stack>
  )
}
