import { FC, useEffect, useMemo, useState } from "react"
import { DataGrid, GridColDef, GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import { jobColumns } from "./columns"
import { theme } from "@src/theme"
import { useFetchContentAcquisitionJobs } from "@src/api/admin/content-acquisition/jobs"
import { Job, Video } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import { CommonUrlParams } from "@src/interfaces/content-acquisition/requestParams"
import { useFetchScheduledVideos } from "@src/api/admin/content-acquisition/videos"
import { useFetchDomains } from "@api/admin/content-acquisition/domains"
import { ViewCloudRunJobLogs } from "./ViewJobLogs"
import { build_search, QueryOptions } from "../../DataGrid/filtering"

export const JobTable: FC<{ videoSelected?: Video }> = ({ videoSelected }) => {
  const videoIdByUrlQueryParams: CommonUrlParams = {
    attributeFilters: {
      url: videoSelected?.url || "",
    },
    associations: ["jobs"],
  }
  const [queryOptions, setQueryOptions] = useState<QueryOptions>({ sortModel: [{ field: "started_at", sort: "desc" }] })

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  })

  const jobsSearchParams: CommonUrlParams = useMemo(
    () => build_search(queryOptions, paginationModel),
    [{ ...queryOptions }, { ...paginationModel }],
  )

  const { data: videosData } = useFetchScheduledVideos(videoIdByUrlQueryParams, !!videoSelected)
  const { data: jobsData, isLoading } = useFetchContentAcquisitionJobs({
    ...jobsSearchParams,
    ...{ associations: ["proxies", "domains"] },
  })
  const { data: domains } = useFetchDomains()
  const [rows, setRows] = useState<Job[]>([])

  useEffect(() => {
    if (!jobsData) return
    if (!videosData) return setRows(jobsData.jobs)

    const jobsByVideo = videosData.videos.map((video) => video?.jobs)
    setRows(jobsByVideo ? jobsByVideo.flat().filter((job): job is Job => job !== undefined) : [])
  }, [jobsData, videosData])

  const actionColumns: GridColDef[] = [
    {
      field: "viewJobLog",
      headerName: "",
      editable: false,
      flex: 0.5,
      filterable: false,
      sortable: false,
      renderCell: (params) =>
        params.row.execution_id ? <ViewCloudRunJobLogs videoId={params.row.video_id as number} /> : null,
    },
  ]

  return (
    <DataGrid
      autoHeight
      sx={{
        '.MuiDataGrid-booleanCell[data-value="true"]': {
          color: theme.palette.success.main,
        },
        '.MuiDataGrid-booleanCell[data-value="false"]': {
          color: "red",
        },
        marginTop: 2,
        marginBottom: 2,
      }}
      pageSizeOptions={[25, 50, 75, 100]}
      rowCount={jobsData?.metadata.total_count || -1}
      paginationMode="server"
      sortingMode="server"
      onSortModelChange={(sortModel: GridSortModel) => setQueryOptions({ ...queryOptions, sortModel: [...sortModel] })}
      onPaginationModelChange={setPaginationModel}
      onFilterModelChange={(filterModel: GridFilterModel) =>
        setQueryOptions({ ...queryOptions, filterModel: { ...filterModel } })
      }
      density="compact"
      columns={[...jobColumns(domains), ...actionColumns].map((column) => ({
        ...column,
        headerClassName: "super-app-theme--header",
      }))}
      rows={rows}
      loading={isLoading}
      initialState={{
        sorting: {
          sortModel: [{ field: "started_at", sort: "desc" }],
        },
        columns: {
          columnVisibilityModel: {
            updated_at: false,
            created_at: false,
          },
        },
      }}
    />
  )
}
