import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../../axiosInstance"
import { Proxy } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import { CommonUrlParams } from "@src/interfaces/content-acquisition/requestParams"
import { QueryParamsBuilder } from "@src/interfaces/content-acquisition/queryParamsBuilder"

const resourcePath = "/content-acquisition/proxies"

export const useFetchProxies = (params?: CommonUrlParams) => {
  const axiosInstance = useAuthAxios()
  const paramBuilder = new QueryParamsBuilder()
  const queryParams = paramBuilder.build(params)
  const uri = `${resourcePath}${queryParams}`

  return useQuery(paramBuilder.toQueryKey("content-acquistion-proxies"), async () => {
    const response = await (await axiosInstance).get<Proxy[]>(uri)

    return response.data
  })
}

// do not copy paste this method, it bases the request body off of a response object, define the request body instead
export const useCreateProxy = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (requestBody: Partial<Proxy>) => {
      const response = await (await axiosInstance).post<Proxy>(resourcePath, requestBody)
      return response.data
    },
    {
      onSuccess: (_response) => {
        queryClient.invalidateQueries(["content-acquistion-proxies"])
      },
    },
  )
}

// do not copy paste this method, it bases the request body off of a response object, define the request body instead
export const useUpdateProxy = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (requestBody: Partial<Proxy>) => {
      const response = await (await axiosInstance).put<Proxy>(`${resourcePath}/${requestBody.id}`, requestBody)
      return response.data
    },
    {
      onSuccess: (_response) => {
        queryClient.invalidateQueries(["content-acquisition-proxies"])
      },
    },
  )
}

export const useDeleteProxy = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (id: number) => {
      const response = await (await axiosInstance).delete<Proxy>(`${resourcePath}/${id}`)
      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["proxies"])
      },
    },
  )
}
