import { useMutation } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { Person } from "../../interfaces/person"
import { VoiceVerificationStatus, VoiceVerificationStatusToInteger, VoiceWithSpeaker } from "@interfaces/voice"

const prefix = "/admin/voices"

export const useUpdateVoicePersonId = (onSuccess?: (data: VoiceWithSpeaker) => void) => {
  const axiosInstance = useAuthAxios()

  return useMutation(
    async (params: { voice_id: number; person_id?: number }) => {
      return (await axiosInstance).put<VoiceWithSpeaker>(`${prefix}/${params.voice_id}`, {
        person_id: params.person_id,
      })
    },
    {
      onSuccess: (response) => {
        if (onSuccess) {
          onSuccess(response.data)
        }
      },
    },
  )
}

export const useVerifyVoice = (onSuccess?: (verification_status: VoiceVerificationStatus) => void) => {
  const axiosInstance = useAuthAxios()

  return useMutation(
    async (params: { id: number; verification_status: VoiceVerificationStatus }) => {
      const verificationStatusAsInt = VoiceVerificationStatusToInteger[params.verification_status]
      return (await axiosInstance).put<Person>(`${prefix}/${params.id}/verify`, {
        verification_status: verificationStatusAsInt,
      })
    },
    {
      onSuccess: (_, variables) => {
        if (onSuccess) {
          onSuccess(variables.verification_status)
        }
      },
    },
  )
}

export const useUnverifyVoice = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()

  return useMutation(
    async (params: { id: number }) => {
      return (await axiosInstance).put<Person>(`${prefix}/${params.id}/unverify`)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}
