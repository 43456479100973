import { FC, useContext } from "react"
import { Stack, Box, Tabs, Tab } from "@mui/material"
import { ProxyTable } from "./Proxys/ProxyTable"
import { VideoTable } from "./Videos/VideoTable"
import { theme } from "../../../../theme"
import { DomainContainer } from "./Domains/DomainContainer"
import { ProxyDomainConfigTable } from "./ProxyDomainConfigs/ProxyDomainConfigTable"
import { JobsContainer } from "./Jobs/JobsContainer"
import { ContentAcquisitionJobsTableContext, ContentAcquisitionTabs } from "./Context"
import { unpackSetters } from "@src/components/shared/unpackSetters"
import { Banner } from "./Banner"

export const TableContainer: FC = () => {
  const { value, setValue } = useContext(ContentAcquisitionJobsTableContext)
  const { tab } = value
  const { setTab } = unpackSetters(value, setValue)

  const containers = {
    [ContentAcquisitionTabs.VIDEOS]: <VideoTable />,
    [ContentAcquisitionTabs.JOBS]: <JobsContainer />,
    [ContentAcquisitionTabs.DOMAINS]: <DomainContainer />,
    [ContentAcquisitionTabs.PROXIES]: <ProxyTable />,
    [ContentAcquisitionTabs.PROXY_DOMAIN_CONFIGS]: <ProxyDomainConfigTable />,
  }

  return (
    <Stack fontSize="16px" spacing={2} height="fit-content">
      <Banner />
      <Stack
        spacing={1.5}
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            fontSize: "1.25rem",
          },
          "& .super-app-theme--red-row": {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.primary.contrastText,
            ":hover": {
              backgroundColor: theme.palette.error.dark,
            },
          },
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
        }}
        bgcolor="white"
        padding={3}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={(_e, value: ContentAcquisitionTabs) => {
              setTab(value)
            }}
            aria-label="proxy provider tabs"
            textColor={"primary"}
            indicatorColor="primary"
          >
            <Tab
              value={ContentAcquisitionTabs.VIDEOS}
              label="Video Requests"
              component="h2"
              sx={{ fontSize: "1.953em !important" }}
            />
            <Tab
              value={ContentAcquisitionTabs.JOBS}
              label="Jobs"
              component="h2"
              sx={{ fontSize: "1.953em !important" }}
            />
            <Tab
              value={ContentAcquisitionTabs.DOMAINS}
              label="Domains"
              component="h2"
              sx={{ fontSize: "1.953em !important" }}
            />
            <Tab
              value={ContentAcquisitionTabs.PROXIES}
              label="Proxies"
              component="h2"
              sx={{ fontSize: "1.953em !important" }}
            />
            <Tab
              value={ContentAcquisitionTabs.PROXY_DOMAIN_CONFIGS}
              label="Domain Proxy Settings"
              component="h2"
              sx={{ fontSize: "1.953em !important" }}
            />
          </Tabs>
        </Box>
        {containers[tab]}
      </Stack>
    </Stack>
  )
}
