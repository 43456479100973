import { Domain } from "@interfaces/content-acquisition/responseObjectInterfaces"
import { getGridDateOperators, getGridNumericOperators, getGridStringOperators, GridColDef } from "@mui/x-data-grid"
import CircularProgress from "@mui/material/CircularProgress"

export const jobColumns: (domains?: Domain[]) => GridColDef[] = (domains) => [
  {
    field: "id",
    headerName: "ID",
    flex: 0.5,
    type: "number",
    filterOperators: getGridNumericOperators().filter((operator) => operator.value === "="),
  },
  {
    field: "video_id",
    headerName: "Video ID",
    flex: 0.75,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === "equals"),
  },
  {
    field: "domain_id",
    headerName: "Domain ID",
    flex: 1,
    type: "number",
    filterOperators: getGridNumericOperators().filter((operator) => operator.value === "="),
  },
  {
    field: "domain_name",
    headerName: "Domain",
    flex: 1,
    filterable: false,
    sortable: false,
    type: "string",
    renderCell: (params) => params.row?.domains?.domain,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === "equals"),
  },
  {
    field: "proxy_id",
    headerName: "Proxy ID",
    flex: 0.75,
    type: "number",
    filterOperators: getGridNumericOperators().filter((operator) => operator.value === "="),
  },
  {
    field: "proxy_name",
    headerName: "Proxy Name",
    flex: 1,
    filterable: false,
    sortable: false,
    renderCell: (params) => params.row?.proxies?.name || "",
  },
  {
    field: "proxy_type",
    headerName: "Proxy Type",
    flex: 0.75,
    renderCell: (params) => params.row?.proxies?.agent_type || "",
    filterable: false,
    sortable: false,
  },
  {
    field: "in_progress",
    headerName: "In Progress",
    flex: 0.75,
    type: "boolean",
    renderCell: (params) => (params.value ? <CircularProgress size={20} /> : null),
  },
  { field: "success", headerName: "Success", flex: 1, type: "boolean" },

  {
    field: "started_at",
    headerName: "Started",
    valueGetter: (value) => new Date(value as string),
    renderCell: (params) => new Date(params.value as string).toLocaleString(),
    type: "dateTime",
    flex: 1,
    filterOperators: getGridDateOperators().filter((operator) => operator.value === "is"),
  },
  {
    field: "finished_at",
    headerName: "Finished",
    valueGetter: (value) => new Date(value as string),
    renderCell: (params) => new Date(params.value as string).toLocaleString(),
    type: "dateTime",
    flex: 1,
    filterOperators: getGridDateOperators().filter((operator) => operator.value === "is"),
  },
  {
    field: "error",
    headerName: "Error Message",
    flex: 1,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === "equals"),
  },
  {
    field: "execution_id",
    headerName: "Execution ID",
    flex: 1,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === "equals"),
  },
  {
    field: "created_at",
    headerName: "Created",
    valueGetter: (value) => new Date(value as string),
    renderCell: (params) => new Date(params.value as string).toLocaleString(),
    type: "dateTime",
    flex: 1,
    filterOperators: getGridDateOperators().filter((operator) => operator.value === "is"),
  },
  {
    field: "updated_at",
    headerName: "Updated At",
    valueGetter: (value) => new Date(value as string),
    renderCell: (params) => new Date(params.value as string).toLocaleString(),
    type: "dateTime",
    flex: 1,
    filterOperators: getGridDateOperators().filter((operator) => operator.value === "is"),
  },
]
