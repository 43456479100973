import { createContext, FC, useEffect, useMemo, useState } from "react"
import { Box, Card, CardContent, Chip, Grid, List, Skeleton, Tooltip } from "@mui/material"
import { useHistory, useLocation } from "react-router"
import { useCreateSuggestedTerm, useFetchSuggestedTerms } from "../../../api/suggested_terms"
import { KeyTermContent } from "./KeyTermsTab"
import { useFetchOrganization } from "../../../api/organizations"
import { ChannelDetailsContent } from "./ChannelDetailsTab"
import { addSearchTermString } from "../../../functions/generateURL"
import { useParams } from "react-router-dom"
import { IMeetingRouteParams } from "../../../api/interfaces"
import { FetchedMeeting, useFetchMeeting } from "../../../api/meetings"
import { ContactsTableContainer } from "../../../containers/MeetingDetailContainers/ContactsTableContainer"
import { useFetchContacts } from "@api/contacts"
import { Tabs as StatisticsDetailsTabs } from "./tabs"

interface StatisticsDetailsContextProps {
  meeting: FetchedMeeting
  tabValue: string
  setTabValue: (value: string) => void
}

export const StatisticsDetailsCardContext = createContext<StatisticsDetailsContextProps>({
  meeting: {} as FetchedMeeting,
  tabValue: "",
  setTabValue: () => {},
})

const InnerStatisticsDetailsCard: FC<{ meeting: FetchedMeeting }> = ({ meeting }) => {
  const locationSearchParams = useLocation()
  const history = useHistory()
  const {
    data: contactsData,
    isLoading: isContactsLoading,
    isError: isContactsError,
  } = useFetchContacts(meeting.organization_id)
  const { data: suggestedTermsData } = useFetchSuggestedTerms(meeting.id)
  const { data: organizationData, isLoading: isOrganizationDataLoading } = useFetchOrganization(meeting.organization_id)
  const { mutate: createSuggestedTerm } = useCreateSuggestedTerm()
  const [tabValue, setTabValue] = useState("")

  useEffect(() => {
    if (contactsData && contactsData.length > 0) {
      setTabValue("contacts")
    } else {
      setTabValue("details")
    }
  }, [contactsData])

  const onWordSelect = (searchTerm: string) => {
    createSuggestedTerm({ meeting_id: meeting.id, user_sentiment: "positive", term: searchTerm })

    history.push({ search: addSearchTermString(locationSearchParams.search, searchTerm) })
  }

  const suggestedWords = useMemo(() => {
    const stopWords = suggestedTermsData?.suggested_terms.reduce((terms: string[], termObj: any) => {
      if (termObj.user_sentiment === "negative") {
        terms.push(termObj.term)
      }
      return terms
    }, [])

    return meeting.important_words
      ?.filter((word: string) => !stopWords?.includes(word))
      .sort((a: any, b: any) => (a < b ? -1 : 1))
  }, [meeting, suggestedTermsData])

  const contactsTabValue = () => {
    return <ContactsTableContainer />
  }

  const detailsTabValue = () => {
    return <ChannelDetailsContent organization={organizationData} />
  }

  const keyTermsTabValue = () => {
    return (
      <Box sx={{ overflow: "auto", maxHeight: 400, padding: 2 }}>
        <List>
          {suggestedWords?.map((word: string) => <KeyTermContent key={word} word={word} meeting={meeting} />)}
        </List>
      </Box>
    )
  }

  const mostUsedWordsTabValue = () => {
    return (
      <Grid item container xs={12} spacing={1} padding={2}>
        {meeting.metadata?.word_counts?.map((word) => (
          <Grid item key={word.join("-")}>
            <Chip label={word[0]} onClick={() => onWordSelect(word[0])} />
          </Grid>
        ))}
      </Grid>
    )
  }

  const renderTabValues = useMemo(() => {
    let tabElement = detailsTabValue()

    if (tabValue === "contacts") {
      tabElement = contactsTabValue()
    } else if (tabValue === "keyTerms") {
      tabElement = keyTermsTabValue()
    } else if (tabValue === "mostUsedWords") {
      tabElement = mostUsedWordsTabValue()
    }

    if (isContactsLoading || isOrganizationDataLoading) {
      return <Skeleton variant="rectangular" height={400} />
    } else {
      return tabElement
    }
  }, [contactsData, isContactsLoading, isContactsError, tabValue, isOrganizationDataLoading])

  return (
    <Card>
      <CardContent>
        <StatisticsDetailsCardContext.Provider value={{ meeting, tabValue, setTabValue }}>
          <StatisticsDetailsTabs />
          {renderTabValues}
        </StatisticsDetailsCardContext.Provider>
      </CardContent>
    </Card>
  )
}

export const StatisticsDetailsCard: FC = () => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data } = useFetchMeeting(meetingId)
  if (data) {
    return <InnerStatisticsDetailsCard meeting={data.meeting} />
  } else {
    return null
  }
}
