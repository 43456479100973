import { Box, Stack } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { TranscriptWithPerson, TranscriptWord } from "../../../../interfaces/transcript"
import { HighlightedText } from "./HighlightedText"
import { PersonText } from "./PersonText"
import { TranscriptItemActionComponent } from "./TranscriptItemActions"
import { FetchedMeeting } from "../../../../api/meetings"
import { useHistory, useLocation } from "react-router-dom"
import { generateParamsString } from "../../../../functions/generateURL"

//take the snippet and find where it aligns with the words array and start the array there

const InnerTextComponent = ({
  searchTerm,
  transcript,
  ellipsis,
  transcriptWords,
  lastTranscript,
  isExpanded,
  onNearEnd,
  meeting,
  handleCopyTextFromParagraph,
}: {
  searchTerm: string
  transcript: TranscriptWithPerson
  ellipsis: boolean
  transcriptWords?: TranscriptWord[]
  lastTranscript?: boolean
  isExpanded: boolean
  onNearEnd: () => void
  meeting: FetchedMeeting
  handleCopyTextFromParagraph: () => void
}) => {
  const history = useHistory()
  const searchString = useLocation().search
  const urlParams = new URLSearchParams(searchString)

  const handleOnClick = () => {
    history.push({
      search: generateParamsString({
        transcriptId: transcript.id,
        activeTerm: searchTerm,
        prevParams: searchString,
        refresh: transcript.id.toString() === urlParams.get("transcript_id"),
      }),
    })
    document.getElementById("meeting-video-player-anchor-element")?.scrollIntoView()
  }
  return (
    <Box
      border={isExpanded ? 1 : undefined}
      padding={isExpanded ? 1 : undefined}
      borderColor={isExpanded ? "neutral.dark" : undefined}
    >
      <TranscriptItemActionComponent
        actionTranscript={transcript}
        searchTerm={searchTerm}
        meeting={meeting}
        handleCopyTextFromParagraph={handleCopyTextFromParagraph}
        handleOnClick={handleOnClick}
      />
      {transcript.person && <PersonText person={transcript.person} />}
      <HighlightedText
        searchTerm={searchTerm}
        words={transcriptWords ?? transcript.words ?? []}
        ellipsis={ellipsis}
        onNearEnd={lastTranscript ? onNearEnd : undefined}
      />
    </Box>
  )
}

export const TranscriptHighlighter: FC<{
  searchTerm: string
  transcript: TranscriptWithPerson
  transcriptWords?: TranscriptWord[]
  previousTranscript?: TranscriptWithPerson
  nextTranscript?: TranscriptWithPerson
  meeting: FetchedMeeting
  handleCopyTextFromParagraph: () => void
  isExpanded?: boolean
  loadNextTranscript?: (nextTranscript: TranscriptWithPerson) => void
}> = ({
  searchTerm,
  transcript,
  transcriptWords,
  previousTranscript,
  nextTranscript,
  meeting,
  handleCopyTextFromParagraph,
  isExpanded = false,
  loadNextTranscript,
}) => {
  const [onNearEndTriggered, setOnNearEndTriggered] = useState(false)

  const onNearEnd = () => {
    // Grab the next transcript
    if (onNearEndTriggered) {
      return
    }
    if (nextTranscript) {
      if (loadNextTranscript) {
        loadNextTranscript(nextTranscript)
      }
      setOnNearEndTriggered(true)
    }
  }

  useEffect(() => {
    // Reset the near end trigger when the transcript changes
    setOnNearEndTriggered(false)
  }, [transcript.id])

  return (
    <Stack spacing={1.5}>
      {previousTranscript && (
        <InnerTextComponent
          key={previousTranscript.id}
          searchTerm={searchTerm}
          transcript={previousTranscript}
          ellipsis={false}
          isExpanded={isExpanded}
          onNearEnd={onNearEnd}
          meeting={meeting}
          handleCopyTextFromParagraph={handleCopyTextFromParagraph}
        />
      )}
      <InnerTextComponent
        searchTerm={searchTerm}
        transcript={transcript}
        ellipsis={!previousTranscript && !nextTranscript}
        transcriptWords={transcriptWords}
        key={transcript.id}
        isExpanded={isExpanded}
        onNearEnd={onNearEnd}
        meeting={meeting}
        handleCopyTextFromParagraph={handleCopyTextFromParagraph}
      />
      {nextTranscript && (
        <InnerTextComponent
          key={nextTranscript.id}
          searchTerm={searchTerm}
          transcript={nextTranscript}
          ellipsis={false}
          lastTranscript={true}
          isExpanded={isExpanded}
          onNearEnd={onNearEnd}
          meeting={meeting}
          handleCopyTextFromParagraph={handleCopyTextFromParagraph}
        />
      )}
    </Stack>
  )
}
