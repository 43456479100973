import { useFetchContentAcquisitionJobs } from "@api/admin/content-acquisition/jobs"
import { fetchYesterdaysJobsParams } from "@interfaces/content-acquisition/query/jobs"
import { Job } from "@interfaces/content-acquisition/responseObjectInterfaces"
import { Stack, Typography } from "@mui/material"
import { FC, useMemo } from "react"

export const Banner: FC = () => {
  const { data: jobsforLessOneDayData } = useFetchContentAcquisitionJobs(fetchYesterdaysJobsParams)
  const getSuccessRates = (jobs: Job[] | undefined) => {
    if (!jobs) return "0%"
    let total = 0
    let success = 0
    jobs.forEach((job) => {
      total++
      if (job.success) success++
    })
    if (total === 0) return "0%"
    return `${Math.round((success / total) * 100)}%`
  }

  const dailySuccessRates = useMemo(
    () => [
      {
        label: "1 Day",
        stat: getSuccessRates(jobsforLessOneDayData?.jobs),
        key: 1,
      },
    ],
    [jobsforLessOneDayData],
  )

  return (
    <Stack
      spacing={1.5}
      alignItems="center"
      sx={{
        display: "inline-flex",
        bgcolor: "primary.dark",
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
      }}
      paddingBottom={1.25}
    >
      <Typography fontSize="1.953em" color="white" component="h2" variant="h2" sx={{ paddingLeft: 2, paddingTop: 1 }}>
        Job Success Rate
      </Typography>
      <Stack spacing={5} direction="row">
        {dailySuccessRates.map((rate) => (
          <Typography fontSize="2.441em" variant="h1" component="h1" color="white" key={rate.key}>
            {rate.label}: {rate.stat}
          </Typography>
        ))}
      </Stack>
    </Stack>
  )
}
