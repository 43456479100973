import { PublicBriefing } from "@interfaces/briefing"
import { User } from "@interfaces/user"

export const constructBriefingsCollection = (briefings: PublicBriefing[], currentUser: User) => {
  const ownBriefings = [] as PublicBriefing[]
  const othersBriefings = [] as PublicBriefing[]

  // order briefings by date descending
  briefings.sort((a, b) => new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf())

  briefings.forEach((briefing) => {
    if (briefing.user_email?.trim().toLowerCase() === currentUser.email.trim().toLowerCase()) {
      ownBriefings.push(briefing)
    } else {
      othersBriefings.push(briefing)
    }
  })

  return { ownBriefings, othersBriefings }
}
