import { useMutation } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { generateMinnieURL } from "@functions/generateURL"

const prefix = generateMinnieURL("minnie/admin/opportunities")

export const useAnalyzeOpportunities = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()

  return useMutation(
    async (params: { id: number | number[] }) => {
      return (await axiosInstance).post(`${prefix}/analyze`, params)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}
