import { FC, useContext, useMemo, useState } from "react"
import { DataGrid, GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import { useFetchScheduledVideos } from "@src/api/admin/content-acquisition/videos"
import { GridColDef } from "@mui/x-data-grid/models/colDef"
import { theme } from "@src/theme"
import { ViewCloudRunJobLogs } from "../Jobs/ViewJobLogs"
import { ContentAcquisitionJobsTableContext, ContentAcquisitionTabs } from "../Context"
import { unpackSetters } from "@src/components/shared/unpackSetters"
import { Button } from "@mui/material"
import { videoColumns } from "./columns"
import { Video } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import { CommonUrlParams } from "@interfaces/content-acquisition/requestParams"
import { build_search, QueryOptions } from "../../DataGrid/filtering"

export const VideoTable: FC = () => {
  const { value, setValue } = useContext(ContentAcquisitionJobsTableContext)
  const { setVideoSelected, setTab } = unpackSetters(value, setValue)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  })

  const [queryOptions, setQueryOptions] = useState<QueryOptions>({ sortModel: [{ field: "created_at", sort: "desc" }] })

  const videosSearchParams: CommonUrlParams = useMemo(
    () => build_search(queryOptions, paginationModel),
    [{ ...queryOptions }, { ...paginationModel }],
  )

  const { data: videosData, isLoading } = useFetchScheduledVideos(videosSearchParams)

  const actionColumns: GridColDef[] = [
    {
      field: "viewAllJobs",
      headerName: "",
      editable: false,
      flex: 0.5,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Button
          onClick={() => {
            if (setVideoSelected) setVideoSelected(params.row as Video)
            setTab(ContentAcquisitionTabs.JOBS)
          }}
        >
          JOB HISTORY
        </Button>
      ),
    },
    {
      field: "viewJobLog",
      headerName: "",
      editable: false,
      filterable: false,
      flex: 0.5,
      sortable: false,
      renderCell: (params) =>
        params.row.attempts > 0 ? <ViewCloudRunJobLogs videoId={params.row.id as number} /> : null,
    },
  ]

  return (
    <DataGrid
      sx={{
        '.MuiDataGrid-booleanCell[data-value="true"]': {
          color: theme.palette.success.main,
        },
        '.MuiDataGrid-booleanCell[data-value="false"]': {
          color: "red",
        },
      }}
      density="compact"
      loading={isLoading}
      rows={videosData?.videos || []}
      autoHeight
      rowCount={videosData?.metadata.total_count || -1}
      pageSizeOptions={[25, 50, 75, 100]}
      paginationModel={paginationModel}
      paginationMode="server"
      sortingMode="server"
      onSortModelChange={(sortModel: GridSortModel) => setQueryOptions({ ...queryOptions, sortModel: [...sortModel] })}
      onPaginationModelChange={setPaginationModel}
      onFilterModelChange={(filterModel: GridFilterModel) =>
        setQueryOptions({ ...queryOptions, filterModel: { ...filterModel } })
      }
      filterMode="server"
      columns={[...videoColumns, ...actionColumns].map((column) => ({
        ...column,
        headerClassName: "super-app-theme--header",
      }))}
      initialState={{
        sorting: {
          sortModel: [{ field: "created_at", sort: "desc" }],
        },
        columns: {
          columnVisibilityModel: {
            checksum: false,
            bucket: false,
            error: false,
          },
        },
      }}
    />
  )
}
