import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { Person } from "@interfaces/person"
import { AdminContact } from "@src/interfaces/contact"

const prefix = "/admin/persons"

export interface FetchPersonsResponse {
  persons: Person<AdminContact>[]
  total: number
}

export const useFetchPersons = (
  filterParams?: URLSearchParams,
  include_voices?: boolean,
  include_contact?: boolean,
  enabled: boolean = true,
) => {
  const axiosInstance = useAuthAxios()

  const apiParams = new URLSearchParams({
    name: filterParams?.get("name") || "",
    page: filterParams?.get("page") || "0",
    count: filterParams?.get("count") || "25",
    organization: filterParams?.get("organization") || "",
    title: filterParams?.get("title") || "",
    sort_by: filterParams?.get("sort_by") || "id",
    sort_order: filterParams?.get("sort_order") || "asc",
  })
  // TODO - use UrlBuilder on next refactor
  const voiceIds = filterParams?.get("voice_ids")?.split(",")
  if (voiceIds && voiceIds.length > 0) {
    voiceIds.forEach((voice_id) => {
      apiParams.append("voice_ids[]", voice_id)
    })
  }

  if (apiParams.get("count") === "-1") {
    // remove pagination params if count is -1
    apiParams.delete("count")
    apiParams.delete("page")
  }
  if (include_voices) {
    apiParams.set("include_voices", "true")
  }
  if (include_contact) {
    apiParams.set("include_contact", "true")
  }

  return useQuery(
    [
      "admin",
      "persons",
      {
        name: apiParams?.get("name"),
        page: apiParams?.get("page"),
        count: apiParams?.get("count"),
        organization: apiParams?.get("organization"),
        title: apiParams?.get("title"),
        sort_by: apiParams?.get("sort_by"),
        sort_order: apiParams?.get("sort_order"),
        voice_ids: apiParams?.getAll("voice_ids"),
        include_voices: include_voices,
        include_contact: include_contact,
      },
    ],
    async () => {
      const response = await (await axiosInstance).get<FetchPersonsResponse>(`${prefix}?${apiParams?.toString()}`)
      return response.data
    },
    { enabled },
  )
}

export const useFetchPersonsMap = (
  filterParams?: URLSearchParams,
  include_voices?: boolean,
  include_contact?: boolean,
  enabled: boolean = true,
) => {
  const fetchData = useFetchPersons(filterParams, include_voices, include_contact, enabled)
  if (fetchData.data) {
    return {
      ...fetchData,
      data: new Map(fetchData.data.persons.map((person) => [person.id, person])),
    }
  }
  return fetchData
}

export const useCreatePerson = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { name: string; title: string; organization: string }) => {
      return (await axiosInstance).post<Person>(`${prefix}`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["admin", "persons"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useUpdatePerson = (onSuccess?: (data: Person<AdminContact>) => void) => {
  const axiosInstance = useAuthAxios()

  return useMutation(
    async (params: { id: number; name: string; title: string; organization: string }) => {
      return (await axiosInstance).put<Person<AdminContact>>(`${prefix}/${params.id}`, params)
    },
    {
      onSuccess: (response) => {
        if (onSuccess) {
          onSuccess(response.data)
        }
      },
    },
  )
}

export interface MergePersonsParams {
  primary_person_id: number
  people_to_merge_ids: number[]
}

export const useMergePeople = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: MergePersonsParams) => {
      return (await axiosInstance).post(`${prefix}/merge_people`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["admin", "persons"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}
