import { createContext, FC } from "react"
import { useFetchCurrentUserEmail } from "@api/users"
import { useAuthAxios } from "@api/axiosInstance"
import { SimpleUser } from "@interfaces/user"
import axios, { AxiosInstance } from "axios"
import { useQuery } from "react-query"

export interface SystemUserData extends SimpleUser {}

export interface SystemUserContextDetails {
  data: SystemUserData | undefined
  queryStatus: {
    loading: boolean
    error: string | undefined
    status: string | undefined
  }
}

export const InitialSystemUserContextDetails = {
  data: undefined,
  queryStatus: {
    loading: false,
    error: undefined,
    status: undefined,
  },
}

export const SystemUserContext = createContext<SystemUserContextDetails>(InitialSystemUserContextDetails)

const fetchSystemUserData = async (axiosInstanceContext: Promise<AxiosInstance>): Promise<SystemUserData> => {
  try {
    const axiosInstance = await axiosInstanceContext
    const response = await axiosInstance.get<SystemUserData>("/users/me")

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios Error", error.response?.data)
      throw new Error(`Axios Error: ${error.message}, Status: ${error.response?.status}`)
    } else {
      console.error("Unexpected Error", error)
      throw new Error("An unexpected error occurred.")
    }
  }
}

export const SystemUserContextProvider: FC = ({ children }) => {
  const axiosInstanceContext = useAuthAxios()
  const queryResult = useQuery(
    ["current_user_context", {}],
    async () => {
      return await fetchSystemUserData(axiosInstanceContext)
    },
    {
      // DO NOT use the "enabled" option here or it will cause 1 - 3 second delay for no reason
    },
  )

  const contextDetails: SystemUserContextDetails = {
    data: queryResult.data,
    queryStatus: {
      loading: queryResult.isLoading,
      error: `${queryResult.error}`,
      status: queryResult.status,
    },
  }

  return <SystemUserContext.Provider value={contextDetails}>{children}</SystemUserContext.Provider>
}
