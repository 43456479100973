import { FC, useState, useEffect } from "react"
import {
  Button,
  Card,
  CardContent,
  Dialog,
  Stack,
  DialogContent,
  DialogTitle,
  Fab,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material"
import { marked } from "marked"
import { sanitize } from "dompurify"
import { useFetchCurrentUser } from "@api/users"
import {
  useFetchMeetingBriefings,
  useFetchMeeting,
  useFetchMeetingBriefingCreditUsagesMap,
  useCreatePersonalBriefing,
} from "@api/meetings"
import { useParams } from "react-router-dom"
import { IMeetingRouteParams } from "../../../api/interfaces"
import { useCreatePDFAndDownload } from "../Shared/BriefingPDF"
import { getPrompts } from "../../../api/prompts"
import { PersonalBriefingCreation } from "./PersonalBriefingCreation"
import { useToast } from "../../shared/ToastHook"
import AddIcon from "@mui/icons-material/Add"
import { BriefingContent } from "../Shared/BriefingContent"
import { PublicBriefing } from "@src/interfaces/briefing"
import { constructBriefingsCollection } from "./constructBriefingCollection"
import { constructBriefingCards } from "./constructBriefingCards"

export const PersonalBriefing: FC = () => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { mutate: createPersonalBriefing, isLoading: briefingIsLoading } = useCreatePersonalBriefing(meetingId)
  const { data: currentUser } = useFetchCurrentUser()
  const { data: fetchedMeeting } = useFetchMeeting(meetingId)
  const { data: briefingCreditUsagesMap } = useFetchMeetingBriefingCreditUsagesMap(meetingId)
  const { data: unfilteredBriefings, refetch } = useFetchMeetingBriefings(meetingId, {
    onSuccess: (briefingData) => {
      if (
        briefingData.some((briefing) => {
          if (!briefing.result) {
            const createTime = new Date(briefing.created_at)
            if (Date.now() - createTime.valueOf() < 120000) {
              // briefing was created less than 2 minutes ago so possibly still processing
              return true
            }
          }
          return false
        })
      ) {
        setTimeout(refetch, 10000) // check again in 10 seconds
      }
    },
  })
  const { data: prompts, isFetched: promptsIsFetched } = getPrompts("personal")
  const toast = useToast()
  const [selectedPrompts, setSelectedPrompts] = useState<Set<number>>(new Set())
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const close = () => setIsDialogOpen(false)

  useEffect(() => {
    if (prompts && promptsIsFetched && selectedPrompts.size === 0) {
      setSelectedPrompts(new Set([prompts[0].id]))
    }
  }, [promptsIsFetched])

  const personalizedBriefings = unfilteredBriefings?.filter(({ is_personalized }) => is_personalized)

  let ownBriefings: PublicBriefing[] = []
  let othersBriefings: PublicBriefing[] = []
  if (personalizedBriefings && currentUser) {
    const filteredBriefings = personalizedBriefings.filter(({ name }) => name)
    const briefings = constructBriefingsCollection(filteredBriefings, currentUser)
    ownBriefings = briefings.ownBriefings
    othersBriefings = briefings.othersBriefings
  }

  let briefings: PublicBriefing[] = []
  if (ownBriefings.length > 0 || othersBriefings.length > 0) {
    briefings = [...ownBriefings, ...othersBriefings]
  }

  let briefingContent: JSX.Element = <></>
  if (othersBriefings.length > 0 || ownBriefings.length > 0) {
    if (briefingCreditUsagesMap) {
      briefingContent = (
        <>
          {ownBriefings.length > 0 && <Typography variant="h6">My Briefings</Typography>}
          {constructBriefingCards(ownBriefings, briefingCreditUsagesMap)}
          {ownBriefings.length > 0 && othersBriefings.length > 0 && <Divider />}
          {othersBriefings.length > 0 && <Typography variant="h6">All Briefings</Typography>}
          {constructBriefingCards(othersBriefings, briefingCreditUsagesMap)}
        </>
      )
    }
  } else if (personalizedBriefings && personalizedBriefings.length > 0) {
    const newestBriefing = personalizedBriefings[0]
    briefingContent = <BriefingContent promptResult={newestBriefing.result} />
  }

  const createPDFAndDownload = useCreatePDFAndDownload(
    <div
      dangerouslySetInnerHTML={{
        __html: sanitize(
          marked.parse(
            briefings.map(({ name, result }) => `### ${name}\n\n${result}`).join("\n\n---\n\n") || "",
          ) as string,
        ),
      }}
    />,
    fetchedMeeting?.meeting,
  )

  if (personalizedBriefings && personalizedBriefings.length > 0) {
    return (
      <>
        <Stack spacing={1}>
          <Stack direction="row">
            <Stack direction="row" spacing={1} flex="1">
              <Button variant="contained" onClick={createPDFAndDownload}>
                Full Briefing PDF
              </Button>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(
                    briefings.map(({ name, result }) => `### ${name}\n\n${result}`).join("\n\n"),
                  )
                  toast("Full Briefing Copied to clipboard")
                }}
              >
                Copy Full Briefing to Clipboard
              </Button>
            </Stack>
            {briefingIsLoading ? (
              <CircularProgress />
            ) : (
              <Fab size="small" color="primary" onClick={() => setIsDialogOpen(true)}>
                <AddIcon />
              </Fab>
            )}
          </Stack>
          {briefingContent}
        </Stack>
        <Dialog open={isDialogOpen} onClose={close}>
          <DialogTitle>Create Personal Briefing</DialogTitle>
          <DialogContent>
            <PersonalBriefingCreation
              onConfirmCreatePrompt={createPersonalBriefing}
              onSuccess={() => {
                close()
              }}
              currentUsersBriefings={ownBriefings}
              isLoading={briefingIsLoading}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  } else {
    return (
      <Card>
        <CardContent>
          <PersonalBriefingCreation onConfirmCreatePrompt={createPersonalBriefing} isLoading={briefingIsLoading} />
        </CardContent>
      </Card>
    )
  }
}
