import { FC, useState } from "react"
import { MeetingListItem } from "../components/Promo/MeetingListItem"
import { useHistory, useLocation } from "react-router"
import { useFetchSampleMeetings } from "../api/promotions"
import { Meeting } from "../interfaces/meeting"
import { Stack, Box, Typography } from "@mui/material"
import { SearchContainer } from "../components/Promo/search/SearchContainer"
import Loading from "../components/shared/Loading"
import { NoResultsContainer } from "@src/components/shared/NoResultsContainer"
import { useOpenModalKey } from "../components/shared/OpenModalHook"
import { ScheduleDemoModal, scheduleKey } from "../components/Promo/ScheduleDemoModal"

export const SearchPromotionPage: FC = () => {
  const locationSearchParams = useLocation().search
  const urlSearchParams = new URLSearchParams(locationSearchParams)
  const queryTerms = urlSearchParams.get("terms")
  const paramChannelType = urlSearchParams.get("channel_type")
  const searchChannelTypes = paramChannelType === null || parseInt(paramChannelType) > 4 ? "4" : paramChannelType
  const searchTerms = queryTerms === null || queryTerms.trim().length === 0 ? [] : queryTerms.split(",")
  const [terms, setTerms] = useState<Set<string>>(new Set(searchTerms))
  const [channelType, setChannelType] = useState(searchChannelTypes)
  const history = useHistory()

  const updateSearchParams = () => {
    urlSearchParams.set("terms", Array.from(terms).toString())
    urlSearchParams.set("channel_type", channelType)
    history.push({ search: decodeURIComponent(urlSearchParams.toString()) })
  }

  const { data, isLoading } = useFetchSampleMeetings(searchTerms, searchChannelTypes)
  const openModalKey = useOpenModalKey(scheduleKey)

  return (
    <Box paddingTop={5} display="flex" minHeight="100vh" width="100%" bgcolor="neutral.main" justifyContent="center">
      <Stack width={{ xs: 400, sm: 550, md: 800, lg: 1000, xl: 1500 }}>
        <ScheduleDemoModal />
        <SearchContainer
          searchTerms={terms}
          setSearchTerms={setTerms}
          updateSearchParams={updateSearchParams}
          channelType={channelType}
          setChannelType={setChannelType}
        />
        {data?.meetings.length > 0 ? (
          <>
            <Stack direction="row" spacing={2} justifyContent={{ xs: "center", sm: "space-between" }}>
              <Stack alignItems="center">
                <Typography variant="h3" sx={{ color: "primary.dark", fontSize: 60 }}>
                  {data?.total_meetings}
                </Typography>
                <Typography variant="h2" sx={{ color: "primary.dark" }}>
                  Meetings Found
                </Typography>
              </Stack>
              <Stack alignItems="center">
                <Typography variant="h3" sx={{ color: "primary.dark", fontSize: 60 }}>
                  {data?.total_hits}
                </Typography>
                <Typography variant="h2" sx={{ color: "primary.dark" }}>
                  Search Term Hits
                </Typography>
              </Stack>
            </Stack>
            <br />
            <Box width={{ xs: 400, sm: 550, md: 900, lg: 1000, xl: 1500 }}>
              {data.meetings.map((meeting: Meeting) => (
                <MeetingListItem searchWords={Array.from(terms)} meeting={meeting} key={meeting.id} />
              ))}
              <Box width={{ xs: 400, sm: 550, md: 800, lg: 1000, xl: 1500 }} onClick={openModalKey}>
                {data.meetings.map((meeting: Meeting) => (
                  <MeetingListItem searchWords={Array.from(terms)} meeting={meeting} key={meeting.id} blur />
                ))}
              </Box>
            </Box>
          </>
        ) : (
          <>{isLoading ? <Loading /> : <NoResultsContainer />}</>
        )}
      </Stack>
    </Box>
  )
}
