import { Card, CardContent, Link, Skeleton, Stack, Tab, Tabs, Typography } from "@mui/material"
import { FC, useContext, useEffect, useState } from "react"
import LaunchIcon from "@mui/icons-material/Launch"
import { ChannelDetailContext } from "../../containers/ChannelDetailContainer"
import { ContactsTable } from "../shared/ContactsTable"
import { useFetchContacts } from "../../api/contacts"

export const ExternalLinksTabContent: FC = () => {
  const { organization } = useContext(ChannelDetailContext)

  return (
    <Stack gap={2} padding={1}>
      {organization?.home_page_url && (
        <Typography variant="h4">
          <Link href={organization.home_page_url} target="_blank">
            Home Page
            <LaunchIcon sx={{ paddingTop: "3px", "&:hover": { color: "#0a5dc2" } }} fontSize="small" />
          </Link>
        </Typography>
      )}
      {organization?.contact_page_url && (
        <Typography variant="h4">
          <Link sx={{ "&:hover": { color: "#0a5dc2" } }} href={organization.contact_page_url} target="_blank">
            Contact Page
            <LaunchIcon sx={{ paddingTop: "3px" }} fontSize="small" />
          </Link>
        </Typography>
      )}
      {!organization?.home_page_url && !organization?.contact_page_url && (
        <Typography variant="h4">Not Available</Typography>
      )}
    </Stack>
  )
}

export const ContactsTabContent: FC = () => {
  const { organization } = useContext(ChannelDetailContext)

  return (
    <Stack paddingTop={2} maxHeight={336} overflow="auto">
      {organization && <ContactsTable organizationId={organization.id} />}
    </Stack>
  )
}

export const LinksContactsCard: FC = () => {
  const { organization } = useContext(ChannelDetailContext)
  const [tabValue, setTabValue] = useState("")
  const { data: contactsData, isLoading } = useFetchContacts(organization?.id)

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    if (contactsData && contactsData.length === 0) {
      setTabValue("externalLinks")
    } else if (contactsData && contactsData.length > 0) {
      setTabValue("contacts")
    }
  }, [contactsData])

  return (
    <Card>
      <CardContent sx={{ padding: 3 }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab
            label="Contacts"
            value="contacts"
            sx={{ display: !contactsData || contactsData?.length === 0 ? "none" : null }}
          />
          <Tab label="External Links" value="externalLinks" />
        </Tabs>
        {isLoading && <Skeleton variant="rectangular" height={100} />}
        {tabValue === "externalLinks" && <ExternalLinksTabContent />}
        {tabValue === "contacts" && <ContactsTabContent />}
      </CardContent>
    </Card>
  )
}
